import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import React, {useState} from "react";
import useHttpRequest from "../../hooks/useHttpRequest";

const AddDoctorModal = (props) => {
    const { isLoading, error, sendRequest: addDoctor } = useHttpRequest();
    const [form, setForm] = useState({
        doctorId:"",
        name:"",
        emailId: "",
        password: "",
        phoneNumber: "",
        role: "DOCTOR"
    })

    const onInputChange = (event) => {
        setForm({
            ...form,
            [event.target.name]: event.target.value,
        });
    }

    const onSubmitForm = (event) => {
        event.preventDefault();
        if(form.emailId !== "" && form.password !==""){
            addDoctor(
                { url: '/doctors/add-doctor',
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body : JSON.stringify(form)
                },
            ).then((res)=> {
                console.log("response : "+res);
                props.handleClose()
            }, (err) =>{
                console.log("eerror : "+err);
            });
        }
    }

    return (
        <>
        <Modal show={props.show} onHide={props.handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Add Doctor</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={onSubmitForm}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Doctor Id</Form.Label>
                        <Form.Control name="doctorId" value={form.doctorId} onChange={onInputChange} disabled type="number" placeholder="" />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Email Id</Form.Label>
                        <Form.Control name="emailId" value={form.emailId} onChange={onInputChange} type="text" placeholder="Email Id" />

                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Password</Form.Label>
                        <Form.Control name="password" value={form.password} onChange={onInputChange} type="text" placeholder="Password" />

                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Phone Number</Form.Label>
                        <Form.Control name="phoneNumber" value={form.phoneNumber} onChange={onInputChange} type="number" placeholder="Phone Number" />

                    </Form.Group>
                    <Button variant="primary" type="submit">
                        Submit
                    </Button>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
        </>

    )
}

export default AddDoctorModal;