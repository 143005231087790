import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import {Col, Row} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import React, {useContext, useState} from "react";
import useHttpRequest from "../../hooks/useHttpRequest";
import AuthContext from "../../context/AuthContext";
import NotificationAlert from "../NotificationAlert/NotificationAlert";


const AddPrescription = (props) => {
    const { id, name, token, clinicId} = useContext(AuthContext)
    const durationUnit = ["Days", "Weeks"];
    const medicine = {
        name:"",
        description:"",
        duration:"",
        durationUnit:durationUnit[0]
    }
    let medicines = [medicine]
    if(props.medicines !== undefined){
        medicines = props.medicines;
    }
    const [prescriptionMedicines, setPrescriptionMedicines] = useState(medicines);
    const { isLoading, error, sendRequest: addPrescription } = useHttpRequest();



    const addMoreHandler = () => {
        setPrescriptionMedicines([
            ...prescriptionMedicines,
            medicine
        ]);
    }

    const handleResponse = (response) => {
        console.log(response);
        props.handleClose()
    }

    const onSubmitForm = (event) => {
        event.preventDefault();
        let url = '/appointments/' + props.appointmentId + '/prescription';
        if(props.prescriptionId !== undefined) {
            url = '/prescriptions/' + props.prescriptionId + '/update'
        }
        if(prescriptionMedicines[0].name != null) {
            addPrescription(
                {
                    url: url,
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        patientId:props.patientId,
                        doctorId:id,
                        medicines: prescriptionMedicines,
                        clinicId: clinicId

                    })
                },
                handleResponse
            )
        }
    }

    const onInputChange = (event) => {

        const newPrescription = [...prescriptionMedicines];
        newPrescription[event.target.attributes.index.value][event.target.name]= event.target.value;
        setPrescriptionMedicines(newPrescription);

        console.log(prescriptionMedicines);
    }
    return(
        <>
            <Modal size="lg" show={props.show} onHide={props.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Prescription # {props.appointmentId}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {error &&
                    <NotificationAlert
                        variant="danger"
                        message={error}
                        heading="Error"
                        ttl="3000"
                        show="true"
                    />
                    }
                    <Form onSubmit={onSubmitForm}>
                            {prescriptionMedicines?.map((med, index)=>{
                                return <Row key={index}>
                                    <Col>
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Label>Name</Form.Label>
                                            <Form.Control index={index} name="name" onChange={onInputChange} value={med.name} type="text" placeholder="" />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Label>Notes</Form.Label>
                                            <Form.Control index={index} name="description" onChange={onInputChange} value={med.description} type="text" placeholder="" />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Label>Duration(In Days)</Form.Label>
                                            <Form.Control index={index} name="duration" onChange={onInputChange} value={med.duration} type="text" placeholder="" />
                                        </Form.Group>
                                    </Col>

                                </Row>
                            })}
                        <Row>
                            <Col>
                                <Button onClick={addMoreHandler} variant="primary" type="button">
                                    Add More
                                </Button>
                            </Col>

                        </Row>
                        <br/>
                        <br/>

                        <Button variant="primary" type="submit">
                            Submit
                        </Button>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default AddPrescription;