import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import {Col, Row} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import React from "react";
import useHttpRequest from "../../hooks/useHttpRequest";
import NotificationAlert from "../NotificationAlert/NotificationAlert";


const AddVitalSigns = (props) => {
    const { isLoading, error, sendRequest: addVitalSigns } = useHttpRequest();
    const [form, setForm] = React.useState({
        patientId: props.patientId,
        weight: "",
        bloodPressure: "",
        heartBeatRate: "",
        tempreture: "",
        respiratoryRate: "",
        height: "",
        muscle: "",
        bmi: "",
        fat: "",
        boneWeight: "",
        spo: "",
        bloodSugar: "",
        fatMass: "",
        basalMetabolic: "",
        waterLevel: "",
        totalMoisture: "",
        fatFreeBodyWeight: "",
        skeletalMuscle: "",
        proteinMass: "",
        inorganicSalt: "",
        visceralFat: "",
        boneMineralContent: "",
        physicalAge: "",
        comprehensive: "",
        bloodPressureSystolic: "",
        bloodPressureDiastolic: "",
        appointmentId: props.appointmentId,
    });

    const handleResponse = (response) => {
        console.log(response);
        props.handleClose()
    }

    const onSubmitForm = (event) => {
        event.preventDefault();
        if(form.appointmentId != null) {
            addVitalSigns(
                {
                    url: '/appointments/' + props.appointmentId + '/vital-signs',
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(form)
                },
                handleResponse
            )
        }
    }
    const onInputChange = (event) => {
        setForm({
            ...form,
            [event.target.id]: event.target.value,
        });
    }

    return(
        <>
            <Modal size="lg" show={props.show} onHide={props.handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Add Vital  Signs # {props.appointmentId}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {error &&
                <NotificationAlert
                    variant="danger"
                    message={error}
                    heading="Error"
                    ttl="3000"
                    show="true"
                />
                }
                <Form onSubmit={onSubmitForm}>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Weight(in Kg)</Form.Label>
                                <Form.Control onChange={onInputChange} value={form.weight} id="weight" type="text" placeholder="" />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Height(in CM)</Form.Label>
                                <Form.Control onChange={onInputChange}  value={form.height} id="height" type="text" placeholder="" />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>BMI</Form.Label>
                                <Form.Control onChange={onInputChange}  value={form.bmi} id="bmi" type="text" placeholder="" />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>SPO2 (%)</Form.Label>
                                <Form.Control onChange={onInputChange}  value={form.spo} id="spo" type="text" placeholder="" />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Pulse(Heart beats/min)</Form.Label>
                                <Form.Control onChange={onInputChange}  value={form.heartBeatRate} id="heartBeatRate" type="text" placeholder="" />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Temperature (°C)</Form.Label>
                                <Form.Control onChange={onInputChange}  value={form.tempreture} id="tempreture" type="text" placeholder="" />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Fat PCT(%)</Form.Label>
                                <Form.Control onChange={onInputChange}  value={form.fat} id="fat" type="text" placeholder="" />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Fat Mass (Kg)</Form.Label>
                                <Form.Control onChange={onInputChange}  value={form.fatMass} id="fatMass" type="text" placeholder="" />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Basal Metabolic (Kcal)</Form.Label>
                                <Form.Control onChange={onInputChange}  value={form.basalMetabolic} id="basalMetabolic" type="text" placeholder="" />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Water Level PCT.(%)</Form.Label>
                                <Form.Control onChange={onInputChange}  value={form.waterLevel} id="waterLevel" type="text" placeholder="" />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Total Moisture (Kg)</Form.Label>
                                <Form.Control onChange={onInputChange}  value={form.totalMoisture} id="totalMoisture" type="text" placeholder="" />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Fat Free Body Weight (Kg)</Form.Label>
                                <Form.Control onChange={onInputChange}  value={form.fatFreeBodyWeight} id="fatFreeBodyWeight" type="text" placeholder="" />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Skeletal Muscle Rate (%)</Form.Label>
                                <Form.Control onChange={onInputChange}  value={form.skeletalMuscle} id="skeletalMuscle" type="text" placeholder="" />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Protein Mass (Kg)</Form.Label>
                                <Form.Control onChange={onInputChange}  value={form.proteinMass} id="proteinMass" type="text" placeholder="" />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Inorganic Salt (Kg)</Form.Label>
                                <Form.Control onChange={onInputChange}  value={form.inorganicSalt} id="inorganicSalt" type="text" placeholder="" />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Visceral Fat (Kg)</Form.Label>
                                <Form.Control onChange={onInputChange}  value={form.visceralFat} id="visceralFat" type="text" placeholder="" />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Bone Mineral Content (Kg)</Form.Label>
                                <Form.Control onChange={onInputChange}  value={form.boneMineralContent} id="boneMineralContent" type="text" placeholder="" />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Physical Age</Form.Label>
                                <Form.Control onChange={onInputChange}  value={form.physicalAge} id="physicalAge" type="text" placeholder="" />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Comprehensive</Form.Label>
                                <Form.Control onChange={onInputChange}  value={form.comprehensive} id="comprehensive" type="text" placeholder="" />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>BP Systolic (mmHg)</Form.Label>
                                <Form.Control onChange={onInputChange}  value={form.bloodPressureSystolic} id="bloodPressureSystolic" type="text" placeholder="" />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>BP Diastolic (mmHg)</Form.Label>
                                <Form.Control onChange={onInputChange}  value={form.bloodPressureDiastolic} id="bloodPressureDiastolic" type="text" placeholder="" />
                            </Form.Group>
                        </Col>
                    </Row>


                    <Button variant="primary" type="submit">
                        Submit
                    </Button>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
        </>
    )
}

export default AddVitalSigns;
