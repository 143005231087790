import {Card} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import React, {useState} from "react";
import FileModal from "../Files/FileModal";

const ClinicalNote = (props) => {
    const [showModal, setShowModal] = useState(false);
    const [fileUrl, setFileUrl] = useState("");

    const viewFile = (event) => {
        setFileUrl(event.target.attributes.file.value);
        setShowModal(true);
    }

    const handleClosModal = () => {
        setShowModal(false);
    }
    return(
        <>
            {props.notes && props.notes.map((note, index) => {
                return  (
                    <div key={index}>
                        <p><span dangerouslySetInnerHTML={ { __html: note.description } }></span> </p>

                        {note.imageUrl &&
                        <Card style={{ width: '18rem' }}>
                            <Card.Img variant="top" src={note.imageUrl} />
                            <Card.Body>
                                <Button variant="primary" file={note.imageUrl} onClick={viewFile}>View File</Button>
                            </Card.Body>
                        </Card>

                        }

                    </div>

                )
            })}
            <FileModal show={showModal}  fileUrl={fileUrl} handleClose={handleClosModal} />

        </>
    )
}

export default ClinicalNote;