import React from 'react';
import {BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip,} from 'chart.js';
import {Bar} from 'react-chartjs-2';
// import { faker } from 'faker';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);




const MonthlyPatientAcquisitionChart = (props) => {
    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Patient Acquisition Data',
            },
        },
    };
    if(props?.data === undefined ){
        return null;
    }

    const labels = props?.data?.data?.map(d => d.month+ "/"+ d.year);
    function random_rgba() {
        const o = Math.round, r = Math.random, s = 255;
        return 'rgba(' + o(r()*s) + ',' + o(r()*s) + ',' + o(r()*s) + ',' + r().toFixed(1) + ')';
    }

    const randomBorderAndBgColor = ()  => {
        const o = Math.round, r = Math.random, s = 255;
        const border = 'rgba(' + o(r()*s) + ',' + o(r()*s) + ',' + o(r()*s) + ')';
        const bg = 'rgba(' + o(r()*s) + ',' + o(r()*s) + ',' + o(r()*s) + ','+0.5+ ')';
        return {
            border: border,
            bg: bg
        }
    }
    const acquisitionChannels = ['Google', 'Facebook', 'Instagram', 'LinkedIn', 'YouTube', 'Other_Social', 'Newspaper', 'Magazine', 'Other_Online_Article', 'Referral']


    const data = {
        labels,
        datasets: acquisitionChannels.map(dataset => {
            return {
                label: dataset,
                data: props?.data?.data?.filter(x=> x.acquisitionChannel === dataset).map(x=>x.count),
                // data: labels.map(()=> props?.data?.data?.map(x => x.acquisitionChannel === dataset.acquisitionChannel)?.map(x => x.count)),
                backgroundColor: randomBorderAndBgColor().bg,
                borderColor: randomBorderAndBgColor().border
            }
        }),
    };

    return <Bar options={options} data={data} />;
}

export default MonthlyPatientAcquisitionChart;