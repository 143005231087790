import {Alert} from "react-bootstrap";

const {useState} = require("react");


const NotificationAlert = (props) => {
    const [show, setShow] = useState(props.show);

    setTimeout(() => setShow(false), 3000);

        return (
            <div>
                {show &&
                <Alert variant={props.variant} onClose={() => setShow(false)} dismissible>
                    <Alert.Heading>{props.heading}</Alert.Heading>
                    <p>{props.message}</p>
                </Alert>
            }
            </div>
        );

}

export default NotificationAlert;