import {Card} from "react-bootstrap";
import React, {useState} from "react";
import Button from "react-bootstrap/Button";
import FileModal from "./FileModal";

const File = (props) => {
    const [showModal, setShowModal] = useState(false);
    const [fileUrl, setFileUrl] = useState("");

    const viewFile = (event) => {
        setFileUrl(event.target.attributes.file.value);
        setShowModal(true);
    }

    const handleClosModal = () => {
        setShowModal(false);
    }
    return(
        <>
            {props.files.map(file =>{
                return (
                    <Card key={file} style={{ width: '18rem' }}>
                        <Card.Img variant="top" src={file} />
                        <Card.Body>
                            <Button variant="primary" file={file} onClick={viewFile}>View File</Button>
                        </Card.Body>
                    </Card>

                )
            })}

            <FileModal show={showModal}  fileUrl={fileUrl} handleClose={handleClosModal} />
        </>
    )
}

export default File;