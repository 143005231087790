import React from 'react';
import {Alert, Container} from 'react-bootstrap';
import Layout from "./Layout";


const Unauthorized = () => {
    return (
        <>
            <Layout>
                <br/>
                <Container>
                    <Alert variant="danger">
                        <Alert.Heading>Unauthorized Access!</Alert.Heading>
                        <p>
                            You are not authorized to access this page.
                        </p>
                    </Alert>
                </Container>
            </Layout>
        </>
    );
}

export default Unauthorized;