import React, {useContext, useEffect, useRef, useState} from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import DatePicker from "react-datepicker";
import useHttpRequest from "../../hooks/useHttpRequest";
import AuthContext from "../../context/AuthContext";
import NotificationAlert from "../NotificationAlert/NotificationAlert";

const AddAppointmentModal = (props) => {
    const { id, name, token, clinicId} = useContext(AuthContext)
    const [startDate, setStartDate] = useState(new Date());
    const [doctorId, setDoctorId] = useState(null);
    const { isLoading, error, sendRequest: addAppointment } = useHttpRequest();
    const [patientId, setPatientId]= useState(props.patientId);
    const [doctors, setDoctors] = useState(null);
    const { sendRequest: fetchDoctors } = useHttpRequest();
    const notes = useRef();

    useEffect(() => {
        const transformDoctors= (doctors) => {

            setDoctors(doctors);
        };
        fetchDoctors(
            { url: '/clinics/'+clinicId+'/doctors',
                method: "GET"
            },
            transformDoctors
        );
        return () => {
            // cleaning up the listeners here
        }
    }, []);


    const onSelectDoctor = (event) => {
        setDoctorId(event.currentTarget.value);
    }

    const handleResponse = (response) => {
        console.log(response);
        props.handleClose()
    }

    const onSubmitForm = (event) => {
        event.preventDefault();
        if(startDate != null && patientId != null && doctorId != null){
            addAppointment(
                { url: '/appointments/create',
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body : JSON.stringify({
                        patientId: patientId,
                        doctorId: doctorId,
                        startTime: startDate,
                        clinicId:clinicId,
                        notes: notes.current.value
                        })
                },
                handleResponse
            )

        }


    }

    const patientIdHandler  = (event) => {
        setPatientId(event.currentTarget.value);
    }


    return (
        <>
            <Modal show={props.show} onHide={props.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Appointment</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {error &&
                    <NotificationAlert
                        variant="danger"
                        message={error}
                        heading="Error"
                        ttl="3000"
                        show="true"
                    />
                    }
                    <Form onSubmit={onSubmitForm}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Patient Id</Form.Label>
                            <Form.Control value={patientId} onChange={patientIdHandler} type="number" placeholder="Enter Patient Id" />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Choose date & time</Form.Label>
                            <DatePicker
                                customInput={<Form.Control />}
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                                locale="pt-BR"
                                showTimeSelect
                                timeFormat="p"
                                timeIntervals={15}
                                dateFormat="Pp"
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Doctor Id</Form.Label>
                            <Form.Control value={doctorId} disabled  type="number" placeholder="Enter Doctor Id" />

                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicCheckbox">
                            <Form.Select onChange={onSelectDoctor} aria-label="Default select example">
                                <option key={0}>Select Doctor</option>
                                {doctors && doctors.map(doc => {
                                    return <option  key={doc.id} value={doc.id}>{doc.name}</option>
                                })}

                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Notes</Form.Label>
                            <Form.Control ref={notes} type="text" placeholder="Comment" />

                        </Form.Group>
                        <Button variant="primary" type="submit">
                            Submit
                        </Button>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default AddAppointmentModal;