import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import React, {useEffect, useState} from "react";
import useHttpRequest from "../../hooks/useHttpRequest";
import {Col, Row, Table} from "react-bootstrap";
import {formatDate} from "../../AppUtility";
import Pagination from "../Pagination/Pagination";

const PatientTransactionsList = (props) => {
    const [transactions,setTransactions] = useState([]);
    const [pageNumber, setPageNumber] = useState(0);
    const { isLoading, error, sendRequest: fetchTransactions } = useHttpRequest();

    useEffect(() => {
        const transformTransactions = (transations) => {

            setTransactions(transations);
        };
        fetchTransactions(
                { url: '/finance/transactions/patient/'+props.patientId+"/?pageNumber="+pageNumber,
                    method: "GET",
                    headers: {
                        'Content-Type': 'application/json'
                    }
                },
                transformTransactions
            );
    }, [pageNumber]);

    const onPrevious = () => {
        const updated = pageNumber
        setPageNumber(updated-1)
    }

    const onNext = () => {
        const updated = pageNumber
        setPageNumber(updated+1)
    }

    return(
        <>
            <Modal size="lg" show={props.show} onHide={props.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Transactions</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table striped bordered hover  responsive>
                        <tr>
                            <th>Id</th>
                            <th>Date</th>
                            <th>Amount</th>
                            <th>Type</th>
                            <th>Comment</th>
                        </tr>
                        {transactions?.content !=null && transactions.content.map(tr => {
                            return(
                                <tr>
                                    <td>{tr.id}</td>
                                    <td>{formatDate(tr.createdAt)}</td>
                                    <td>&#8377; {tr.amount}</td>
                                    <td>{tr.transactionType}</td>
                                    <td>{tr.comment}</td>
                                </tr>
                            )
                        })}

                    </Table>
                    <Row>
                        <Col md={{ span: 3, offset: 9}}><h4>Balance: &#8377; {props.balance}</h4></Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Pagination
                        itemsPerPage={transactions?.size}
                        items={transactions?.totalElements}
                        totalPages ={transactions?.totalPages}
                        onPravious={onPrevious}
                        onNext={onNext}

                    />
                    <Button variant="secondary" onClick={props.handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default PatientTransactionsList;