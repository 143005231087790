import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import React, {useContext, useState} from "react";
import useHttpRequest from "../../hooks/useHttpRequest";
import AuthContext from "../../context/AuthContext";
import NotificationAlert from "../NotificationAlert/NotificationAlert";


const AddPayment = (props) =>{
    const paymentMethod = ['Cash', 'Cheque' ,"Paytm","NetBanking","Card"];
    const { isLoading, error, sendRequest: addPayment } = useHttpRequest();
    const { id, name, token, clinicId} = useContext(AuthContext)
    const [form, setForm] = useState({
        patientId:props.patientId,
        amount:0,
        paymentMode:"",
        comment:"",
        doctorId:id,
        clinicId: clinicId
    });

    const handleResponse = (response) => {
        console.log(response);
        props.handleClose()
    }

    const onSubmitForm = (event) => {
        event.preventDefault();
        if(paymentMethod.indexOf(form.paymentMode) >=0 && parseInt(form.amount) >0) {
            addPayment(
                {
                    url: '/patients/'+props.patientId+'/add-payment',
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(form)
                },
                handleResponse
            )
        }
    }

    const onInputChange = (event) => {
        setForm({
            ...form,
            [event.target.name]: event.target.value,
        });
    }

    const onSelectPaymentMode = (event) => {
        setForm({
            ...form,
            paymentMode: event.target.value,
        });
    }
    return(
        <>
            <Modal size="lg" show={props.show} onHide={props.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Payment </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {error &&
                    <NotificationAlert
                        variant="danger"
                        message={error}
                        heading="Error"
                        ttl="3000"
                        show="true"
                    />
                    }
                    <Form onSubmit={onSubmitForm}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Amount</Form.Label>
                            <Form.Control name="amount" onChange={onInputChange} value={form.amount} type="number" placeholder="" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Select  value={form.paymentMode} id="paymentMode" name="paymentMode" onChange={onSelectPaymentMode} aria-label="Default select example">
                                <option key={0}>Select Payment Mode</option>
                                {paymentMethod.map((type) => (
                                    <option  key={type} value={type}>{type}</option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Comment</Form.Label>
                            <Form.Control name="comment" onChange={onInputChange} value={form.comment} type="text" placeholder="" />
                        </Form.Group>
                        <br/>
                        <br/>
                        <Button variant="primary" type="submit">
                            Submit
                        </Button>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default AddPayment;