import {Table} from "react-bootstrap";

const Prescription = (props) => {

    return(
    <>
        <Table  bordered hover size="sm">
            <thead>
            <tr>
                <th>Name</th>
                <th>Description</th>
                <th>Duration</th>
            </tr>
            </thead>
            <tbody>
            {props.prescription.medicines?.map((med) => {
                return (<tr  key={med.name}>
                <td>{med.name}</td>
                <td>{med.description}</td>
                <td>{med.duration} Days </td>
                </tr>)
            })}


            </tbody>
        </Table>
    </>
)
}
export default Prescription;