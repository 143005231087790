import {useCallback, useContext, useState} from "react";
import AuthContext from "../context/AuthContext";
import {useNavigate} from "react-router-dom";


const useHttpRequest = () => {
    const authCtx = useContext(AuthContext);
    let navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const sendRequest = useCallback(async (requestConfig, applyData) => {
        setIsLoading(true);
        setError(null);
        let baseUrl = 'https://api.tamariaclinic.com/api/v1';
        try {
            // if(process.env.NODE_ENV !== 'production'){
            //     baseUrl = 'http://localhost:8081/api/v1'
            // }

            const response = await fetch(`${baseUrl}`+requestConfig.url, {
                method: requestConfig.method ? requestConfig.method : 'GET',
                headers: requestConfig.headers ? {...requestConfig.headers, "Authorization": `Bearer ${authCtx.token}`} : {"Authorization": `Bearer ${authCtx.token}`},
                body: requestConfig.body ? requestConfig.body : null,
            });
            if(response.status === 401){
                const path = "/login";
                navigate(path);
            }

            if (!response.ok) {
                throw new Error('Request failed!');
            }

            const data = await response.json();
            applyData(data);
        } catch (err) {
            setError(err.message || 'Something went wrong!');
        }
        setIsLoading(false);
    }, []);

    return {
        isLoading,
        error,
        sendRequest,
    };

}

export default useHttpRequest;