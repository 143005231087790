import Layout from "../../UI/Layout";
import {Card, Col, Container, Row, Table} from "react-bootstrap";
import Alert from "react-bootstrap/Alert";
import Pagination from "../Pagination/Pagination";
import React, {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import AuthContext from "../../context/AuthContext";
import useHttpRequest from "../../hooks/useHttpRequest";
import {formatDate} from "../../AppUtility";


const HealthCardList = () => {
    let navigate = useNavigate();
    const { id, name, token, clinicId} = useContext(AuthContext)
    const [healthCards, setHealthCards] = useState(null);
    const [pageNumber, setPageNumber] = useState(0)
    const { isLoading, error, sendRequest: fetchHealthCards } = useHttpRequest();

    useEffect(() => {
        const transformHealthCards = (healthCards) => {

            setHealthCards(healthCards);
        };

            fetchHealthCards(
                { url: '/health-cards?pageNumber='+pageNumber+'&clinicId='+clinicId,
                    method: "GET"
                },
                transformHealthCards
            );

    }, [pageNumber])

    const onPrevious = () => {
        const updated = pageNumber
        setPageNumber(updated-1)
    }

    const onNext = () => {
        const updated = pageNumber
        setPageNumber(updated+1)
    }
    const gotoPatientVisitPage = (event) =>{
        let path = "/patients/visits/"+event.target.id;
        navigate(path);
    }


    return(
        <>
            <Layout>
                <br/>
                <Container>
                    <Card>
                        <Card.Header>
                            <Row>
                                <Col sm={12} xs={12} md={12}><h3>Health Cards</h3></Col>

                            </Row>
                        </Card.Header>
                        <Card.Body>
                            {healthCards?.content?.length<=0 &&

                                <Alert  variant="light">
                                    <h3>No Health Cards, Please add one !</h3>
                                </Alert>
                            }
                            {healthCards?.content?.length > 0 &&
                                <Table striped bordered hover  responsive>
                                    <thead>
                                    <tr>
                                        <th>Id</th>
                                        <th>Patient</th>
                                        <th>Start Date</th>
                                        <th>End Date</th>
                                        <th>Type</th>
                                        <th>Is Active</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {healthCards?.content !=null && healthCards.content.map(card => {
                                            return <tr key={card.id}>
                                                <td>{card.id}</td>
                                                <td><Alert.Link  onClick={gotoPatientVisitPage} id={card.patientName}>{card.patientName}</Alert.Link></td>
                                                <td>{formatDate(card.subscriptionStartDate)}</td>
                                                <td>{formatDate(card.subscriptionEndDate)}</td>
                                                <td>{card.type}</td>
                                                <td>{card.active? "Active": "Not Active"}</td>
                                            </tr>
                                        }
                                    )}
                                    </tbody>
                                </Table>
                            }

                        </Card.Body>
                        <Card.Footer>
                            <Pagination
                                itemsPerPage={healthCards?.size}
                                items={healthCards?.totalElements}
                                totalPages ={healthCards?.totalPages}
                                onPravious={onPrevious}
                                onNext={onNext}

                            />
                        </Card.Footer>
                    </Card>
                </Container>
            </Layout>
        </>

    )
}

export default HealthCardList;
