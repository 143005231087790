import Header from "../components/common/Header";
// import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import {Component, useContext, useState} from "react";
import {Menu, MenuItem, Sidebar, useProSidebar} from "react-pro-sidebar";
import {
    faArrowLeft,
    faArrowRight,
    faArrowsLeftRightToLine, faBedPulse, faFilePrescription,
    faHome, faHospital, faHospitalUser, faNotesMedical, faReceipt,
    faSquareH,
    faSquarePlus
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import fontawesome from '@fortawesome/fontawesome'
import {faSquare} from "@fortawesome/fontawesome-free-solid";
import {useNavigate} from "react-router-dom";
import AuthContext from "../context/AuthContext";
fontawesome.library.add(faSquareH, faSquarePlus, faArrowsLeftRightToLine,
    faBedPulse, faHospital, faReceipt, faNotesMedical, faFilePrescription, faHospitalUser);



const Layout = ({ children }) => {
    let navigate = useNavigate();
    const {id} = useContext(AuthContext);
    const { collapseSidebar, toggleSidebar, collapsed, toggled, broken, rtl } =
        useProSidebar();

    const toggle = () => {
        toggleSidebar();
        if (toggled) {
            console.log(true);
            collapseSidebar();
        } else {
            console.log(false);
            collapseSidebar();
        }
    };

    const gotoClinicsPage = () => {
        let path = "/clinics";
        navigate(path);
    }

    const gotoAppointmentsPage = () => {
        let path = "/appointments";
        navigate(path);
    }
    const gotoPatientsPage = () => {
        let path = "/patients";
        navigate(path);
    }
    const gotoPrescriptionsPage = () => {
        let path = "/prescriptions";
        navigate(path);
    }
    const gotoFinancePage = () => {
        let path = "/finance/transactions";
        navigate(path);
    }

    const gotoWalletBalancePage = () => {
        let path = "/finance/wallet-balance";
        navigate(path);
    }
    const gotoInvoicesPage = () => {
        let path = "/invoices";
        navigate(path);
    }

    const gotoClinicHomePage = () => {
        let path = "/clinic/"+id;
        navigate(path);
    }

    const gotoHealthCardPage = () => {
        let path = "/health-cards";
        navigate(path);
    }

    const gotoProductListPage = () => {
        let path = "/products";
        navigate(path);
    }

    const gotoOrdersListPage = () => {
        let path = "/orders";
        navigate(path);
    }



    return (
            <div id="app" style={({ height: "100vh",  display: "flex", flexDirection: "row" })}>
                    <Sidebar  breakPoint="sm"
                              transitionDuration={800}
                              backgroundColor="rgb(0, 249, 249, 0.7)"
                              rtl={false}
                              style={{ height: "100vh" }}>
                        <Menu>
                            <MenuItem
                                icon=""
                                onClick={() => {
                                    collapseSidebar();
                                }}
                                style={{ textAlign: "center" }}
                            >
                                {" "}
                                <h2><img
                                    src="/img/tamaria-clinic-icon.png"
                                    width="30"
                                    height="30"
                                    className="d-inline-block align-top"
                                    alt="React Bootstrap logo"
                                /></h2>
                            </MenuItem>

                            <MenuItem onClick={gotoClinicHomePage}  icon={<FontAwesomeIcon icon="fa-solid fa-square-h" />}>Home</MenuItem>
                            <MenuItem onClick={gotoAppointmentsPage}  icon={<FontAwesomeIcon icon="fa-solid fa-hospital-user" />}>Appointments</MenuItem>
                            <MenuItem onClick={gotoClinicsPage} icon={<FontAwesomeIcon icon="fa-solid fa-hospital" />}>Clinics</MenuItem>
                            <MenuItem onClick={gotoPatientsPage} icon={<FontAwesomeIcon icon="fa-solid fa-bed-pulse" />}>  Patients</MenuItem>
                            <MenuItem onClick={gotoInvoicesPage} icon={<FontAwesomeIcon icon="fa-solid fa-receipt" />}>Invoice</MenuItem>
                            <MenuItem onClick={gotoPrescriptionsPage} icon={<FontAwesomeIcon icon="fa-solid fa-notes-medical" />}>Prescriptions</MenuItem>
                            <MenuItem onClick={gotoFinancePage} icon={<FontAwesomeIcon icon="fa-solid fa-file-prescription" />}>Finance</MenuItem>
                            <MenuItem onClick={gotoWalletBalancePage} icon={<FontAwesomeIcon icon="fa-solid fa-wallet" />}>Wallet Balance</MenuItem>
                            <MenuItem onClick={gotoHealthCardPage} icon={<FontAwesomeIcon icon="fa-solid fa-file-prescription" />}>Health Cards</MenuItem>
                            <MenuItem onClick={gotoProductListPage} icon={<FontAwesomeIcon icon="fa-solid fa-prescription-bottle" />}>Products</MenuItem>
                            <MenuItem onClick={gotoOrdersListPage} icon={<FontAwesomeIcon icon="fa-solid fa-cart-plus" />}>Orders</MenuItem>
                        </Menu>
                    </Sidebar>

                <main style={{position: "relative",
                    flexGrow: "1",
                    display: "flex",
                    flexDirection: "column",
                    overflowY: "auto"}}>
                    <Header/>
                    {children}
                </main>
            </div>

    )
}

export default Layout