import {Card, Col, Container, Form, Row} from "react-bootstrap";
import Header from "../common/Header";
import React, {useContext, useEffect, useState} from "react";
import Button from "react-bootstrap/Button";
import useHttpRequest from "../../hooks/useHttpRequest";
import {useParams} from "react-router";
import NotificationAlert from "../NotificationAlert/NotificationAlert";
import {useNavigate} from "react-router-dom";
import AuthContext from "../../context/AuthContext";

const AddUpdatePatient = (props) => {
    let { id, parentId } = useParams();
    const { name, token, clinicId} = useContext(AuthContext)
    let navigate = useNavigate();
    const [notification, setNotification] = useState({
        variant: '',
        heading:'',
        show:false,
        message:'',
        ttl:3000
    })
    const [form, setForm] = React.useState({
        id:"",
        parentId: parentId,
        name: "",
        mobileNumber: "",
        emailId: "",
        relation: "",
        age: "",
        gender:"Male",
        bloodGroup:"",
        acquisitionChannel: "",
        paid: "NO",
        referredBy:"",
        address:"",
        pincode:"",
        city:"",
        medicalHistroy:"",
        active:true,
        clinicId:clinicId
    });
    const { isLoading, error, sendRequest: addUpdatePatient } = useHttpRequest();
    const { sendRequest: fetchPatientBasicInfo } = useHttpRequest();

    const bloodGroups = ['O_Positive', 'O_Negative', 'A_Positive', "A_Negative", "AB_Positive", "AB_Negative", "B_Positive", "B_Negative"];
    const acquisitionChannel = ['Google', 'Facebook', 'Instagram', 'LinkedIn', 'YouTube', 'Other_Social', 'Newspaper', 'Magazine', 'Other_Online_Article', 'Referral']
    const genders = ['Male', 'Female'];
    const onSelectBloodGroup = (event) => {
        setForm({
            ...form,
            bloodGroup: event.target.value,
        });
    }

    const onSelectAcquisitionChannel = (event) => {
        setForm({
            ...form,
            acquisitionChannel: event.target.value,
        });
    }

    const onSelectPaid = (event) =>{
        setForm({
            ...form,
            paid: event.target.value,
        });
    }
    const onSelectGender = (event) => {
        setForm({
            ...form,
            gender: event.target.value,
        });
    }

    useEffect(() => {
        const mapPatient = (patient) => {

            setForm(patient);
        };

        if(id !== null && id !== undefined){
            fetchPatientBasicInfo(
                { url: '/patients/'+id},
                mapPatient
            );
        }


    }, []);

    const updatePatientInfo = (patient) => {
        setForm(patient)
        let path = "/patients/profile/"+patient.id;
        navigate(path);
    }

    const formSubmit= (event) => {
        event.preventDefault();
        if(form.name != null ){
            addUpdatePatient(
                { url: '/patients/add-update',
                    method: "POST",
                    headers: {
                        // 'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body : JSON.stringify(form)
                },
                updatePatientInfo
            ).then((res)=> {
                console.log(error)
                console.log("response : "+res);
                setNotification({
                    variant: error !== undefined ? 'error': 'success',
                    heading: error !== undefined ? error : "Update sucessfully !",
                    show: true
                });
            });

        }


    }

    const onInputChange = (event) => {
        setForm({
            ...form,
            [event.target.id]: event.target.value,
        });
    }

    const onInputCheckChange = () => {
        setForm({
            ...form,
            active: !form.active,
        });
    }

    const gotToProfile = ()  => {
        let path = "/patients/profile/"+id;
        navigate(path);
    }

    return(
        <>
            <Header/>
            <br/>
            <Container>
                <Card>
                    <Card.Header>
                        <Row>
                            <Col  xs={10}>
                                <h4>Patient Info</h4>
                            </Col>
                            <Col xs={2}>
                                {id &&
                                    <Button onClick={gotToProfile}>Go to Profile</Button>
                                }

                            </Col>
                        </Row>
                    </Card.Header>
                    <Card.Body>
                        {notification.show &&
                        <NotificationAlert
                            variant={notification.variant}
                            message={notification.message}
                            heading={notification.heading}
                            ttl={notification.ttl}
                            show={notification.show}
                        />
                        }
                        <Form onSubmit={formSubmit}>
                            <Form.Group className="mb-3">
                                <Form.Label>Parent Id</Form.Label>
                                <Form.Control onChange={onInputChange} value={form.parentId} id="id"  name="id" placeholder="ID" disabled />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>ID</Form.Label>
                                <Form.Control onChange={onInputChange} value={form.id} id="id"  name="id" placeholder="ID" disabled />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Name</Form.Label>
                                <Form.Control value={form.name} name="name" id="name" onChange={onInputChange} type="text" placeholder="Enter Name" />
                                <Form.Text className="text-muted">
                                    Enter Name
                                </Form.Text>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Mobile Number</Form.Label>
                                <Form.Control value={form.mobileNumber} id="mobileNumber" onChange={onInputChange} name="mobileNumber" type="number" placeholder="Enter Mobile" />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Email Id </Form.Label>
                                <Form.Control value={form.emailId} name="emailId" id="emailId" onChange={onInputChange} type="email" placeholder="Enter Email" />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Relation With Patient</Form.Label>
                                <Form.Control value={form.relation} name="relation"  id="relation" onChange={onInputChange} type="text" placeholder="Enter Relation" />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Age</Form.Label>
                                <Form.Control value={form.age} onChange={onInputChange} name="age" id="age" type="text" placeholder="Enter Age" />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Select  value={form.gender}  id="gender" name="gender" onChange={onSelectGender} aria-label="Default select example">
                                    <option key={0}>Select Gender </option>
                                    {genders.map((type) => (
                                        <option  key={type} value={type}>{type}</option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Select  value={form.bloodGroup} id="bloodGroup" name="bloodGroup" onChange={onSelectBloodGroup} aria-label="Default select example">
                                    <option key={0}>Select Blood Group</option>
                                    {bloodGroups.map((type) => (
                                        <option  key={type} value={type}>{type}</option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Acquisition Channel</Form.Label>
                                <Form.Select  value={form.acquisitionChannel} id="acquisitionChannel" name="acquisitionChannel" onChange={onSelectAcquisitionChannel} aria-label="Default select example">
                                    <option key={0}>Acquisition Channel</option>
                                    {acquisitionChannel.map((type) => (
                                        <option  key={type} value={type}>{type}</option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Is Paid Acquisition</Form.Label>
                                <Form.Select  value={form.paid} id="paid" name="paid" onChange={onSelectPaid} aria-label="Default select example">
                                    <option key={0}>Is Paid Acquisition</option>
                                    <option>Yes</option>
                                    <option>No</option>
                                </Form.Select>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Referred By</Form.Label>
                                <Form.Control onChange={onInputChange} id="referredBy" value={form.referredBy} name="referredBy" type="text" placeholder="Referred By" />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Address</Form.Label>
                                <Form.Control onChange={onInputChange} id="address" value={form.address} name="address" type="text" placeholder="Enter Address" />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Pincode</Form.Label>
                                <Form.Control onChange={onInputChange} id="pincode" value={form.pincode} name="pincode" type="text" placeholder="Enter pincode" />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>City</Form.Label>
                                <Form.Control onChange={onInputChange} id="city" value={form.city} name="city" type="text" placeholder="Enter City" />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Medical History</Form.Label>
                                <Form.Control onChange={onInputChange} id="medicalHistroy" value={form.medicalHistroy} name="medicalHistroy" type="text" placeholder="Enter Medical History" />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Active Patient</Form.Label>
                                <Form.Check
                                    onChange={onInputCheckChange}
                                    value={form.active}
                                    checked={form.active}
                                    type="checkbox"
                                    id="active"
                                    label="Active"
                                    name="deactivate"
                                />
                            </Form.Group>

                            <Button variant="primary" type="submit">
                                Submit
                            </Button>
                        </Form>
                    </Card.Body>
                    <Card.Footer>
                        {notification.show &&
                        <NotificationAlert
                            variant={notification.variant}
                            message={notification.message}
                            heading={notification.heading}
                            ttl={notification.ttl}
                            show={notification.show}
                        />
                        }
                    </Card.Footer>
                </Card>

            </Container>
        </>
    )
}

export default AddUpdatePatient