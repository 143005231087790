import {Container, Form} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {useContext, useEffect, useState} from "react";
import useHttpRequest from "../hooks/useHttpRequest";
import {UserContext} from "../context/UserProvider";
import {useNavigate} from "react-router-dom";
import AuthContext from "../context/AuthContext";


const Login = (props) => {
    const [form, setForm] = useState({
        emailId: "",
        password: ""
    })
    let navigate = useNavigate();
    const { isLoading, error, sendRequest: login } = useHttpRequest();
    const [auth, setAuth] = useState(null)
    const authCtx = useContext(AuthContext);

    const onInputChange = (event) => {
        setForm({
            ...form,
            [event.target.name]: event.target.value,
        });
    }

    // useEffect(() => {
    //         setUserDetails(auth)
    //         let path = "/clinics"
    //         navigate(path);
    //
    //
    //
    // }, [auth]);


    const parseLoginRespons = (respons) => {
        authCtx.login(respons)
        let path = "/clinics"
        navigate(path);
        console.log("Login Rsponse"+respons)
    };

    const onSubmitForm = (event) => {
        event.preventDefault();
        if(form.emailId !== "" && form.password !== ""){
            let url = '/auth/login'
            login(
                { url: url,
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body : JSON.stringify(form)
                },
                parseLoginRespons
            ).then((res)=> {
                console.log("response : "+res);
            }, (err) =>{
                console.log("eerror : "+err);
            });

        }


    }

    return(
        <>
            <Container>
                <br/>
                <br/>
                <Form onSubmit={onSubmitForm}>
                    <Form.Group className="mb-3"  controlId="formBasicEmail">
                        <Form.Label>Email address</Form.Label>
                        <Form.Control type="email" name="emailId" value={form.emailId} onChange={onInputChange}  placeholder="Enter email" />
                        <Form.Text className="text-muted">
                            We'll never share your email with anyone else.
                        </Form.Text>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="password" name="password" value={form.password} onChange={onInputChange} placeholder="Password" />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicCheckbox">
                        <Form.Check type="checkbox" label="Check me out" />
                    </Form.Group>
                    <Button variant="primary" type="submit">
                        Login
                    </Button>
                </Form>
            </Container>
        </>

    )
}

export default Login;