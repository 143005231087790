import React, {useContext, useEffect, useState} from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import useHttpRequest from "../../hooks/useHttpRequest";
import Pagination from "../Pagination/Pagination";
import AddAppointmentModal from "./AddAppointmentModal";
import Form from "react-bootstrap/Form";
import {Alert, Card, Col, Container, Row, Table} from "react-bootstrap";
import {formatDate} from "../../AppUtility";
import AuthContext from "../../context/AuthContext";
import Layout from "../../UI/Layout";
import {useNavigate} from "react-router-dom";

const AppointmentList = () => {
        let navigate = useNavigate();
        const { id, name, token, clinicId} = useContext(AuthContext)
        const fromDate = new Date();
        fromDate.setDate(fromDate.getDate()-14)
        const [dateRange, setDateRange] = useState([fromDate, new Date()]);
        const [showModal, setShowModal] = useState(false);
        const [startDate, endDate] =dateRange;
        const [pageNumber, setPageNumber] = useState(0)
        const [appointments, setAppointments] = useState(null);
        const { isLoading, error, sendRequest: fetchAppointments } = useHttpRequest();


        const onChange = (dates) => {
            setDateRange(dates);
        };

        useEffect(() => {
            const transformAppointments = (appointments) => {

                setAppointments(appointments);
            };

            if(startDate != null && endDate != null){
                const body  = {
                    startDate: startDate.toISOString(),
                    endDate: endDate.toISOString(),
                    pageNumber:pageNumber,
                    clinicId: clinicId,
                }
                fetchAppointments(
                    { url: '/appointments?'+Object.keys(body).map(key => key + '=' + body[key]).join('&'),
                        method: "GET"
                        // headers: {
                        //     'Content-Type': 'application/json'
                        // }
                    },
                    transformAppointments
                );
            }
        }, [endDate, pageNumber]);

        const onPrevious = () => {
            const updated = pageNumber
            setPageNumber(updated-1)
        }

        const onNext = () => {
            const updated = pageNumber
            setPageNumber(updated+1)
        }

        const addAppointment = () => {
            setShowModal(true);
        }

        const handleClosModal = () => {
            setShowModal(false);
        }

        const gotoPatientVisitPage = (event) =>{
            let path = "/patients/visits/"+event.target.id;
            navigate(path);
        }

    return (
        <>
            <Layout>
            <br/>
            <Container>
                <Card>
                    <Card.Header>
                        <Row>
                            <Col  xs={7}>
                                <h3>Appointments</h3>
                            </Col>
                            <Col xs={3}>
                                <DatePicker
                                    customInput={<Form.Control />}
                                    selectsRange={true}
                                    startDate={startDate}
                                    endDate={endDate}
                                    onChange={onChange}
                                />
                            </Col>
                            <Col xs={2}>
                                <button type="button" onClick={addAppointment} className="btn btn-primary">Add Appointment</button>
                            </Col>
                        </Row>
                    </Card.Header>
                    <Card.Body>
                        {appointments?.content?.length<=0 &&

                            <Alert  variant="light">
                                <h3>No Appointments, Please create one !</h3>
                            </Alert>
                        }
                        {appointments?.content?.length >0 &&
                        <Table striped bordered hover  responsive>
                            <thead>
                            <tr>
                                <th>Id</th>
                                <th>Patient</th>
                                <th>Start Time</th>
                                <th>End Time</th>
                                <th>Doctor</th>
                                <th>Notes</th>
                                <th>Clinic Id</th>
                            </tr>
                            </thead>
                            <tbody>
                            {appointments?.content !=null && appointments.content.map(appointment => {
                                    return <tr key={appointment.title}>
                                        <td>{appointment.id}</td>
                                        <td><Alert.Link  onClick={gotoPatientVisitPage} id={appointment.patientId}>{appointment.title}</Alert.Link></td>
                                        <td>{formatDate(appointment.startTime)}</td>
                                        <td>{formatDate(appointment.endTime)}</td>
                                        <td>Dr. {appointment.doctor}</td>
                                        <td>{appointment.notes}</td>
                                        <td>{appointment.clinicId}</td>
                                    </tr>
                                }
                            )}

                            </tbody>
                        </Table>
                        }

                    </Card.Body>
                    <Card.Footer>
                        <Pagination
                            itemsPerPage={appointments?.size}
                            items={appointments?.totalElements}
                            totalPages ={appointments?.totalPages}
                            onPravious={onPrevious}
                            onNext={onNext}

                        />
                    </Card.Footer>
                </Card>

            </Container>
            <AddAppointmentModal show={showModal} handleClose={handleClosModal}/>
            </Layout>
        </>

    )

}
;

export default AppointmentList;