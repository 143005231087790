import React, { useState } from 'react';

const AuthContext = React.createContext({
    token: '',
    id: '',
    name: '',
    clinicId: '',
    isLoggedIn: false,
    login: (authResponse) => {},
    setClinicId: (id) => {},
    logout: () => {},
});

export const AuthContextProvider = (props) => {
    let auth = {
        token: "",
        id: "",
        clinicId: "",
        name: "",
        roles: []
    }
    auth= JSON.parse(localStorage.getItem('auth'))


    const [token, setToken] = useState(auth?.token);
    const [id, setId] = useState(auth?.id);
    const [clinicId, setClinicId] = useState(auth?.clinicId);
    const [name, setName] = useState(auth?.name);
    const [roles, setRoles] = useState(auth?.roles);

    const userIsLoggedIn = !!token;

    const loginHandler = (authResponse) => {
        setId(authResponse?.id);
        setToken(authResponse?.token);
        setName(authResponse?.name);
        setRoles(authResponse?.roles);
        localStorage.setItem("auth", JSON.stringify(authResponse))

    };
    const clinicIdHandler = (clinicId) => {
        setClinicId(clinicId);
        let auth = JSON.parse(localStorage.getItem("auth"))
        auth['clinicId'] = clinicId;
        localStorage.setItem("auth",JSON.stringify(auth))
    };

    const logoutHandler = () => {
        setId(null);
        setToken(null);
        setName(null);
        setRoles([]);
    };

    const contextValue = {
        token: token,
        id: id,
        name: name,
        isLoggedIn: userIsLoggedIn,
        clinicId: clinicId,
        roles: roles,
        login: loginHandler,
        logout: logoutHandler,
        setClinicId: clinicIdHandler,
    };

    return (
        <AuthContext.Provider value={contextValue}>
            {props.children}
        </AuthContext.Provider>
    );
};

export default AuthContext;