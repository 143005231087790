import {Card, Col, Container, Form, InputGroup, Row, Table} from "react-bootstrap";
import React, {useContext, useEffect, useState} from "react";
import useHttpRequest from "../../hooks/useHttpRequest";
import Pagination from "../Pagination/Pagination";
import {useNavigate} from "react-router-dom";
import Alert from 'react-bootstrap/Alert';
import AuthContext from "../../context/AuthContext";
import Button from "react-bootstrap/Button";
import Layout from "../../UI/Layout";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons";


const PatientList = () => {
    let navigate = useNavigate();
    const { id, name, token, clinicId} = useContext(AuthContext)
    const [patients, setPatients] = useState(null);
    const [pageNumber, setPageNumber] = useState(0)
    const [searchPatient, setSearchPatient] = useState("")
    const { isLoading, error, sendRequest: fetchPatients } = useHttpRequest();

    useEffect(() => {
        const transformPatients = (patients) => {

            setPatients(patients);
        };

        if(searchPatient === ""){
            fetchPatients(
                { url: '/patients?pageNumber='+pageNumber+'&clinicId='+clinicId,
                    method: "GET"
                },
                transformPatients
            );
        }
        else{
            fetchPatients(
                { url: '/patients/search?value='+searchPatient+'&pageNumber='+pageNumber+'&clinicId='+clinicId,
                    method: "GET"
                },
                transformPatients
            );
        }

    }, [pageNumber, searchPatient])

    const onPrevious = () => {
        const updated = pageNumber
        setPageNumber(updated-1)
    }

    const onNext = () => {
        const updated = pageNumber
        setPageNumber(updated+1)
    }
    const gotoPatientVisitPage = (event) =>{
        let path = "/patients/visits/"+event.target.id;
        navigate(path);
    }


    const onPatientSearch = (event) => {
        setPageNumber(0);
        setSearchPatient(event.target.value);

    }

    const gotoPatientFormPage = (event) =>{
        let path = "/patients/add-patient/";
        navigate(path);
    }

    return(
        <>
            <Layout>
            <br/>
            <Container>
                <Card>
                    <Card.Header>
                        <Row>
                            <Col sm={7} xs={4} md={8}><h3>Patients</h3></Col>
                            <Col sm={3} xs={4} md={2}>
                                <Form>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <InputGroup>
                                            <Form.Control value={searchPatient} onChange={onPatientSearch} type="text" placeholder="Search Patient" />
                                            <InputGroup.Text>
                                                <FontAwesomeIcon icon={faSearch} />
                                            </InputGroup.Text>
                                        </InputGroup>

                                    </Form.Group>
                                </Form>
                            </Col>
                            <Col sm={2} xs={4} md={2}>
                                <Button onClick={gotoPatientFormPage}>Add New Patient</Button>
                            </Col>
                        </Row>
                    </Card.Header>
                    <Card.Body>
                        {patients?.content?.length<=0 &&

                        <Alert  variant="light">
                            <h3>No Patients, Please add one !</h3>
                        </Alert>
                        }
                        {patients?.content?.length > 0 &&
                        <Table striped bordered hover  responsive>
                            <thead>
                            <tr>
                                <th>Id</th>
                                <th>Name</th>
                                <th>Mobile</th>
                                <th># Appointments</th>
                                <th>Last Appointment</th>
                                <th>Next Appointment</th>
                                <th>Wallet Balance</th>
                                <th>Total Income</th>
                            </tr>
                            </thead>
                            <tbody>
                            {patients?.content !=null && patients.content.map(patient => {
                                    return <tr key={patient.id}>
                                        <td>{patient.id}</td>
                                        <td><Alert.Link  onClick={gotoPatientVisitPage} id={patient.id}>{patient.name}</Alert.Link></td>
                                        <td>{patient.mobileNumber}</td>
                                        <td>{patient.appointmentCount}</td>
                                        <td>{patient.lastAppointment}</td>
                                        <td>{patient.nextAppointment}</td>
                                        <td>&#8377; {patient.balance}</td>
                                        <td>&#8377; {patient.totalIncome}</td>
                                    </tr>
                                }
                            )}
                            </tbody>
                        </Table>
                        }

                    </Card.Body>
                    <Card.Footer>
                        <Pagination
                            itemsPerPage={patients?.size}
                            items={patients?.totalElements}
                            totalPages ={patients?.totalPages}
                            onPravious={onPrevious}
                            onNext={onNext}

                        />
                    </Card.Footer>
                </Card>
            </Container>
            </Layout>
        </>

    )
}

export default PatientList;