import React, {useContext, useState} from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import DatePicker from "react-datepicker";
import useHttpRequest from "../../hooks/useHttpRequest";
import NotificationAlert from "../NotificationAlert/NotificationAlert";
import AuthContext from "../../context/AuthContext";

const AddHealthCardModal = (props) => {
    const { clinicId} = useContext(AuthContext)
    const [startDate, setStartDate] = useState(new Date());
    const { isLoading, error, sendRequest: addHealthard } = useHttpRequest();
    const [patientId, setPatientId]= useState(props.patientId);
    const [type, setType]= useState('Individual');
    const [comment, setComment ]= useState('');
    const cardType = ['Individual', 'Family'];

    const handleResponse = (response) => {
        console.log(response);
        props.handleClose()
    }

    const handleCommentChange = (event) => {
        setComment(event.target.value)
    }

    const onSubmitForm = (event) => {
        event.preventDefault();
        if(startDate != null && patientId != null && type != null){
            addHealthard(
                { url: '/healthcard/add',
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body : JSON.stringify({
                        patientId: patientId,
                        comment: comment,
                        startDate: startDate,
                        type:type,
                        clinicId: clinicId
                        })
                },
                handleResponse
            )
        }
    }

    return (
        <>
            <Modal show={props.show} onHide={props.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Health Card</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {error &&
                    <NotificationAlert
                        variant="danger"
                        message={error}
                        heading="Error"
                        ttl="3000"
                        show="true"
                    />
                    }
                    <Form onSubmit={onSubmitForm}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Patient Id</Form.Label>
                            <Form.Control disabled={true} value={props.patientId}  type="number" placeholder="Enter Patient Id" />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Subscription Start date </Form.Label>
                            <DatePicker
                                customInput={<Form.Control />}
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                                locale="pt-BR"
                                showTimeSelect
                                // timeFormat="p"
                                timeIntervals={15}
                                dateFormat="Pp"
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Select  value={type}  id="type" name="type" onChange={setType} aria-label="Default select example">
                                <option key={0}>Select Card Type </option>
                                {cardType.map((type) => (
                                    <option  key={type} value={type}>{type}</option>
                                ))}
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Comment</Form.Label>
                            <Form.Control value={comment} onChange={handleCommentChange}  type="text" placeholder="Enter comment" />

                        </Form.Group>

                        <Button variant="primary" type="submit">
                            Submit
                        </Button>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default AddHealthCardModal;