import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import React, {useRef, useState} from "react";
import useHttpRequest from "../../hooks/useHttpRequest";
import {InputGroup} from "react-bootstrap";
import {faUpload} from "@fortawesome/fontawesome-free-solid";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Editor} from "@tinymce/tinymce-react";
import NotificationAlert from "../NotificationAlert/NotificationAlert";


const AddClinicalNote = (props) => {
    const notes = useRef();
    const editorRef = useRef(null);
    const [imageUrl, setImageUrl ] = useState("");
    const [file, setFile] = useState();
    const { sendRequest: addClinicalNotes } = useHttpRequest();
    const { isLoading, error, sendRequest: uploadImage } = useHttpRequest();

    const updateImageUrl = (data) => {
        setImageUrl(data.url)
    }
    const handleResponse = (response) => {
        console.log(response);
        props.handleClose()
    }

    const onSubmitForm = (event) => {
        event.preventDefault();
        if(notes != null){
            addClinicalNotes(
                { url: '/appointments/'+props.appointmentId+'/clinical-note',
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json'
                       },
                    body : JSON.stringify({
                        note: editorRef.current.getContent(),
                        imageUrl: imageUrl,
                        patientId: props.patientId
                    })
                },
                handleResponse

            )

        }


    }



    const upload = (event) => {
        event.preventDefault();
        if(file !== undefined){
            let data = new FormData();
            data.append("file",file);
            uploadImage(
                { url: '/upload/file',
                    method: "POST",
                    headers: {
                        // "Content-type": "multipart/form-data",
                        // 'content-length': `${file.size}`, // 👈 Headers need to be a string
                    },
                    body : data
                },
                updateImageUrl
            ).then((res)=> {
                console.log("response : "+res);

            }).catch((err) =>{
                console.log("eerror : "+err);
            });
        }
    }

    const onFileChange = (event) =>{
        setFile(event.target.files[0])

        // setImageUrl(file.name)
    }


    return (
        <>
            <Modal size="lg" show={props.show} onHide={props.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Clinical Note for # {props.appointmentId}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {error &&
                    <NotificationAlert
                        variant="danger"
                        message={error}
                        heading="Error"
                        ttl="3000"
                        show="true"
                    />
                    }
                    <Form onSubmit={onSubmitForm}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Notes</Form.Label>
                            {/*<Form.Control ref={notes} type="textarea" placeholder="" />*/}
                            <Editor
                                apiKey='i19l12p1fac08lu8xgcnlultexo550m6hxe7i01cpkldpafz'
                                onInit={(evt, editor) => editorRef.current = editor}
                                initialValue="<p></p>"
                                init={{
                                    height: 300,
                                    menubar: true,
                                    plugins: [
                                        'advlist autolink lists link image charmap print preview anchor',
                                        'searchreplace visualblocks code fullscreen',
                                        'insertdatetime media table paste code help wordcount'
                                    ],
                                    toolbar: 'bullist numlist | formatselect |' +
                                        'bold italic backcolor | alignleft aligncenter ' +
                                        'alignright alignjustify | bullist numlist outdent indent | ' +
                                        'removeformat | help',
                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                }}
                            />
                        </Form.Group>

                        <Form.Label>File</Form.Label>
                        <InputGroup className="mb-3">

                            <Form.Control value="" onChange={onFileChange} type="file" placeholder="File" />
                            <Button size="sm" onClick={upload} variant="outline-secondary" id="button-addon2">
                                <FontAwesomeIcon icon={faUpload} />
                            </Button>
                        </InputGroup>
                        <img src={imageUrl} style={{width: "100px"}}/>
                        <br/>
                        <br/>
                        <Button variant="primary" type="submit">
                            Submit
                        </Button>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default AddClinicalNote;