
// import logo from '../../../public/img/tamaria-clinic-icon.png';

import {Container, Nav, Navbar} from "react-bootstrap";
import NavDropdown from 'react-bootstrap/NavDropdown';
import {useContext} from "react";
import AuthContext from "../../context/AuthContext";

const Header = () => {
    const {name} = useContext(AuthContext);
    return(
        <Navbar bg="info" variant="light">
            <Container>

                {/*<Row>*/}
                {/*    <Col xs={4}>*/}
                        <Navbar.Brand href="/">
                            {/*<img*/}
                            {/*    src="/img/tamaria-clinic-icon.png"*/}
                            {/*    width="30"*/}
                            {/*    height="30"*/}
                            {/*    className="d-inline-block align-top"*/}
                            {/*    alt="React Bootstrap logo"*/}
                            {/*/>*/}
                        </Navbar.Brand>
                    {/*</Col>*/}
                    {/*<Col xs={{ span: 4, offset: 4 }}>*/}
                        <Navbar.Toggle aria-controls="navbar-dark-example" />
                        <Navbar.Collapse id="navbar-dark-example" className="justify-content-end">
                            <Nav>
                                <NavDropdown
                                    id="nav-dropdown-dark-example"
                                    title={name}
                                    menuVariant="dark"
                                >
                                    <NavDropdown.Item href="/clinics">My Clinics</NavDropdown.Item>

                                </NavDropdown>
                            </Nav>
                        </Navbar.Collapse>
                    {/*</Col>*/}
                {/*</Row>*/}
            </Container>
        </Navbar>

    )
}
export default Header;