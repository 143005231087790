import Modal from "react-bootstrap/Modal";
import {useState} from "react";

const FileModal = (props) => {
    const [show, setShow] = useState(props.show);
    const [fileUrl, setFileUrl] = useState("");

    const viewFile = (event) => {
        setFileUrl(event.target.file);
        setShow(true);
    }
    return (
        <>
            <Modal show={props.show} fullscreen={true} onHide={props.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>File</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ padding: '3rem', textAlign:'center' }}>
                    <img  width="90%" src={props.fileUrl} alt="img"/>
                </Modal.Body>
            </Modal>
        </>

    )
}

export default FileModal;