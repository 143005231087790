import Layout from "../../UI/Layout";
import {Card, Col, Container, Row, Table} from "react-bootstrap";
import Alert from "react-bootstrap/Alert";
import Pagination from "../Pagination/Pagination";
import React, {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import AuthContext from "../../context/AuthContext";
import useHttpRequest from "../../hooks/useHttpRequest";
import Button from "react-bootstrap/Button";
import AddProduct from "./AddProduct";


const ProductsList = () => {
    let navigate = useNavigate();
    const { id, name, token, clinicId} = useContext(AuthContext)
    const [products, setProducts] = useState(null);
    const [pageNumber, setPageNumber] = useState(0)
    const { isLoading, error, sendRequest: fetchProducts } = useHttpRequest();
    const [showAddProductOrderModal, setAddShowProductOrderModal] = useState(false);
    const [refresh, setRefresh] = useState(false);

    useEffect(() => {
        const transformProducts = (products) => {

            setProducts(products);
        };

        fetchProducts(
            { url: '/products?pageNumber='+pageNumber+'&clinicId='+clinicId+'&size=50',
                method: "GET"
            },
            transformProducts
        );

    }, [pageNumber])

    const onPrevious = () => {
        const updated = pageNumber
        setPageNumber(updated-1)
    }

    const onNext = () => {
        const updated = pageNumber
        setPageNumber(updated+1)
    }
    const gotoPatientVisitPage = (event) =>{
        let path = "/patients/visits/"+event.target.id;
        navigate(path);
    }

    const openProductAddModal = () => {
        setAddShowProductOrderModal(true);
    }

    const handleCloseOrderProductsModal = () => {
        setAddShowProductOrderModal(false);
        setRefresh(!refresh)
    }


    return(
        <>
            <Layout>
                <br/>
                <Container>
                    <Card>
                        <Card.Header>
                            <Row>
                                <Col sm={9} xs={9} md={9}><h3>Products</h3></Col>
                                <Col sm={2} xs={4} md={2}>
                                    <Button onClick={openProductAddModal}>Add Product</Button>
                                </Col>

                            </Row>
                        </Card.Header>
                        <Card.Body>
                            {products?.content?.length<=0 &&

                                <Alert  variant="light">
                                    <h3>No Products, Please add one !</h3>
                                </Alert>
                            }
                            {products?.content?.length > 0 &&
                                <Table striped bordered hover  responsive>
                                    <thead>
                                    <tr>
                                        <th>Id</th>
                                        <th>Name</th>
                                        <th>Price</th>
                                        <th>In Stock</th>
                                        <th>Quantity</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {products?.content !=null && products.content.map(product => {
                                            return <tr key={product.id}>
                                                <td>{product.id}</td>
                                                <td>{product.name}</td>
                                                <td>{product.price}</td>
                                                <td>{product.inStock ? 'Yes': 'NO'}</td>
                                                <td>{product.availableQuantity}</td>
                                            </tr>
                                        }
                                    )}
                                    </tbody>
                                </Table>
                            }

                        </Card.Body>
                        <Card.Footer>
                            <Pagination
                                itemsPerPage={products?.size}
                                items={products?.totalElements}
                                totalPages ={products?.totalPages}
                                onPravious={onPrevious}
                                onNext={onNext}

                            />
                        </Card.Footer>
                    </Card>
                </Container>
            </Layout>
            {showAddProductOrderModal &&
                <AddProduct
                    show={showAddProductOrderModal}
                    patientId={id}
                    handleClose={handleCloseOrderProductsModal} />
            }
        </>

    )
}

export default ProductsList;
