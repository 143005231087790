import React, {useContext, useEffect, useState} from "react";
import useHttpRequest from "../../hooks/useHttpRequest";
import {Button, Card, Col, Container, Row} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import Header from "../common/Header";
import AuthContext from "../../context/AuthContext";
import NotificationAlert from "../NotificationAlert/NotificationAlert";


const Clinics = (props) => {
    const authCtx = useContext(AuthContext);
    let navigate = useNavigate();
    const { isLoading, error, sendRequest: fetchClinics } = useHttpRequest();
    const [clinics, setClinics] = useState(null);
    useEffect(() => {
        const transformClinics = (clinics) => {

            setClinics(clinics);
        };

            fetchClinics(
                { url: '/clinics?doctorId='+authCtx.id,
                    method: "GET",

                },
                transformClinics
            );
    }, [ ]);

    const gotoClinicPage = (event) =>{
        authCtx.setClinicId(event.target.id);
        let path = "/clinic/"+event.target.id;
        navigate(path);
    }


    return(
        <>
            <Header/>
            <br/>
            <Container>
                <Card>
                    <Card.Header>
                        <Row>
                            <Col  >
                                <h3>Your Clinics</h3>
                            </Col>

                        </Row>
                    </Card.Header>
                    <Card.Body>
                        {error &&
                                <NotificationAlert
                                    variant="danger"
                                    message={error}
                                    heading="Error"
                                    ttl="3000"
                                    show="true"
                                />
                            }
                        <Row>
                            {clinics && clinics?.map((clinic) => {
                                return (
                                    <Col xs="3" key={clinic.id}>
                                        <Card style={{ width: '18rem' }}>
                                            <Card.Img variant="top" src="https://img.icons8.com/color/96/000000/clinic.png" />
                                            <Card.Body style={{ textAlign: 'center' }}>
                                                <Button variant="primary" id={clinic.id} onClick={gotoClinicPage}>{clinic.name}</Button>
                                            </Card.Body>
                                        </Card>
                                    </Col>

                                )
                            })}
                        </Row>

                    </Card.Body>

                </Card>

            </Container>

        </>
    )
}

export default Clinics;