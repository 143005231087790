import './App.css';
import Home from "./UI/Home";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AppointmentList from "./components/Appointment/AppointmentList";
import PatientList from "./components/Patient/PatientList";
import PatientProfile from "./components/Patient/PatientProfile";
import AddUpdatePatient from "./components/Patient/AddUpdatePatient";
import TransactionList from "./components/Transaction/TransactionList";
import PrescriptionList from "./components/Prescription/PrescriptionList";
import InvoiceList from "./components/Invoice/InvoiceList";
import Clinics from "./components/Clinic/Clinics";
import Login from "./UI/Login";
import DoctorList from "./components/Doctor/DoctorList";
import {AuthContextProvider} from "./context/AuthContext";
import ProtectedRoute from "./components/Routes/ProtectedRoutes";
import PatientVisitHistoryTimeline from "./components/Patient/PatientVisitHistoryTimeline";
import WalletBalanceList from "./components/Transaction/WalletBalanceList";
import HealthCardList from "./components/HealthCard/HealthCardList";
import ProductsList from "./components/Products/ProductList";
import OrderList from "./components/Order/OrderList";

function App() {
  return (
      <AuthContextProvider>
          <BrowserRouter>
              <Routes>
                  <Route path='/login' element={<Login />} />
                  <Route index element={
                      <ProtectedRoute allowedRoles={["DOCTOR", "NURSE"]}>
                          <Clinics />
                      </ProtectedRoute>
                  } />
                  <Route path="/clinics"
                         element={
                             <ProtectedRoute allowedRoles={["DOCTOR", "NURSE"]}>
                             <Clinics />
                             </ProtectedRoute>
                         }
                  />
                  <Route path="/clinic/:id" element={
                      <ProtectedRoute allowedRoles={["DOCTOR"]}>
                          <Home />
                      </ProtectedRoute>
                  } />
                  <Route path="/appointments" element={
                      <ProtectedRoute allowedRoles={["DOCTOR", "NURSE"]}>
                          <AppointmentList />
                      </ProtectedRoute>
                  } />
                  <Route path="/patients" element={
                      <ProtectedRoute allowedRoles={["DOCTOR", "NURSE"]}>
                      <PatientList />
                  </ProtectedRoute>

                      } />
                  <Route path="/patients/visits/:id" element={
                      <ProtectedRoute allowedRoles={["DOCTOR", "NURSE"]}>
                          <PatientVisitHistoryTimeline />
                      </ProtectedRoute>
                  } />
                  <Route path="/patients/profile/:id" element={
                      <ProtectedRoute allowedRoles={["DOCTOR", "NURSE"]}>
                          <PatientProfile/>
                      </ProtectedRoute>
                      }
                  />
                  <Route path="/patients/update-patient/:id" element={
                      <ProtectedRoute allowedRoles={["DOCTOR", "NURSE"]}>
                          <AddUpdatePatient/>
                      </ProtectedRoute>

                  } />
                  <Route path="/patients/add-patient" element={
                      <ProtectedRoute allowedRoles={["DOCTOR", "NURSE"]}>
                          <AddUpdatePatient/>
                      </ProtectedRoute>

                  } />
                  <Route path="/patients/add-member/:parentId" element={
                      <ProtectedRoute allowedRoles={["DOCTOR", "NURSE"]}>
                          <AddUpdatePatient/>
                      </ProtectedRoute>

                  } />
                  <Route path="/finance/transactions" element={
                      <ProtectedRoute allowedRoles={["DOCTOR", "NURSE"]}>
                          <TransactionList/>
                      </ProtectedRoute>
                      }
                  />
                  <Route path="/finance/wallet-balance" element={
                      <ProtectedRoute allowedRoles={["DOCTOR", "NURSE"]}>
                          <WalletBalanceList/>
                      </ProtectedRoute>
                  }
                  />
                  <Route path="/prescriptions" element={
                      <ProtectedRoute allowedRoles={["DOCTOR", "NURSE"]}>
                          <PrescriptionList/>
                      </ProtectedRoute>

                  } />
                  <Route path="/invoices" element={
                      <ProtectedRoute allowedRoles={["DOCTOR", "NURSE"]}>
                          <InvoiceList/>
                      </ProtectedRoute>

                  } />
                  <Route path="/doctors" element={
                      <ProtectedRoute allowedRoles={["DOCTOR", "NURSE"]}>
                          <DoctorList/>
                      </ProtectedRoute>

                  } />
                  <Route path="/health-cards" element={
                      <ProtectedRoute allowedRoles={["DOCTOR", "NURSE"]}>
                          <HealthCardList/>
                      </ProtectedRoute>

                  } />
                  <Route path="/products" element={
                      <ProtectedRoute allowedRoles={["DOCTOR", "NURSE"]}>
                          <ProductsList/>
                      </ProtectedRoute>

                  } />
                  <Route path="/orders" element={
                      <ProtectedRoute allowedRoles={["DOCTOR", "NURSE"]}>
                          <OrderList/>
                      </ProtectedRoute>

                  } />
              </Routes>
          </BrowserRouter>
      </AuthContextProvider>
  );
}

export default App;
