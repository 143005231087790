import React, {useState} from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import DatePicker from "react-datepicker";
import useHttpRequest from "../../hooks/useHttpRequest";
import NotificationAlert from "../NotificationAlert/NotificationAlert";

const DeactivateHealthCardModal = (props) => {
    const [endDate, setEndDate] = useState(new Date());
    const { isLoading, error, sendRequest: cancelHealthard } = useHttpRequest();
    const [comment, setComment ]= useState('');

    const handleResponse = (response) => {
        console.log(response);
        props.handleClose()
    }

    const handleCommentChange = (event) => {
        setComment(event.target.value)
    }

    const onSubmitForm = (event) => {
        event.preventDefault();
        if(endDate != null){
            cancelHealthard(
                { url: '/healthcard/cancel',
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body : JSON.stringify({
                        id: props.id,
                        comment: comment,
                        endDate: endDate,
                    })
                },
                handleResponse
            )
        }
    }

    return (
        <>
            <Modal show={props.show} onHide={props.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Cancel Health Card</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {error &&
                    <NotificationAlert
                        variant="danger"
                        message={error}
                        heading="Error"
                        ttl="3000"
                        show="true"
                    />
                    }
                    <Form onSubmit={onSubmitForm}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label> Id</Form.Label>
                            <Form.Control disabled={true} value={props.id}  type="number" placeholder="Enter Patient Id" />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Subscription End date </Form.Label>
                            <DatePicker
                                customInput={<Form.Control />}
                                selected={endDate}
                                onChange={(date) => setEndDate(date)}
                                locale="pt-BR"
                                showTimeSelect
                                // timeFormat="p"
                                timeIntervals={15}
                                dateFormat="Pp"
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Comment</Form.Label>
                            <Form.Control value={comment} onChange={handleCommentChange}  type="text" placeholder="Enter comment" />

                        </Form.Group>

                        <Button variant="primary" type="submit">
                            Submit
                        </Button>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}


export default DeactivateHealthCardModal;