import {Navigate,} from 'react-router-dom';
import * as React from "react";
import AuthContext from "../../context/AuthContext";
import Unauthorized from "../../UI/Unauthorized";

const ProtectedRoute = ({ children, allowedRoles }) => {
    const { token, roles } = React.useContext(AuthContext);

    if (!token) {
        return <Navigate  to="/login" replace />;
    }
    else if(!allowedRoles.some( ai => roles.includes(ai) )){
        return <Unauthorized />
    }

    return children;
};

export default ProtectedRoute;