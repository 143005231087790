

//todo: move to utility class
import moment from "moment";

export const formatDate = (date) => {
    const options = { weekday: 'short', year: 'numeric', month: 'long', day: 'numeric', hour:'2-digit', minute: '2-digit'     };
    return new Date(date).toLocaleDateString("en-US", options)
    //  return new Intl.DateTimeFormat('en-US', { dateStyle: 'full', timeStyle: 'long', timeZone: 'Asia/Kolkata' }).format(new Date(date));
}

export const formatShortDateOnly = (date) => {
    return moment(date).format('DD-MM-YYYY');
}
