import Layout from "../../UI/Layout";
import {Card, Col, Container, Row, Table} from "react-bootstrap";
import Pagination from "../Pagination/Pagination";
import React, {useContext, useEffect, useState} from "react";
import useHttpRequest from "../../hooks/useHttpRequest";
import AuthContext from "../../context/AuthContext";
import {formatShortDateOnly} from "../../AppUtility";


const WalletBalanceList = () => {
    const REQUEST_TYPE = {
        ALL: "All",
        DUE: "Dues"
    }
    const { id, name, token, clinicId} = useContext(AuthContext)
    const { isLoading, error, sendRequest: fetchWalletBalances } = useHttpRequest();
    const [requestType, setRequestType] = useState(REQUEST_TYPE.ALL);
    const [pageNumber, setPageNumber] = useState(0);
    const [walletBalances, setWalletBalances] = useState(null);

    useEffect(() => {
        const transformWalletBalances = (walletBalances) => {

            setWalletBalances(walletBalances);
        };
        const body  = {
            pageNumber:pageNumber,
            clinicId: clinicId,
            type: requestType
        }
        fetchWalletBalances(
            { url: '/finance/wallet-balances?'+Object.keys(body).map(key => key + '=' + body[key]).join('&'),
                method: "GET"
            },
            transformWalletBalances
        );

    }, [ pageNumber, requestType]);

    const changeRequestType = (event) => {
        setRequestType(event.currentTarget.value)
    }

    const onPrevious = () => {
        const updated = pageNumber
        setPageNumber(updated-1)
    }

    const onNext = () => {
        const updated = pageNumber
        setPageNumber(updated+1)
    }

    return(
        <>
            <Layout>
                <br/>
                <Container>
                    <Card>
                        <Card.Header>
                            <Row>
                                <Col sm={8} xs={6} md={7}><h3>Wallet Balances</h3></Col>

                                <Col sm={4} xs={6} md={3}>
                                    <select onChange={changeRequestType}>
                                        <option>{REQUEST_TYPE.ALL}</option>
                                        <option>{REQUEST_TYPE.DUE}</option>
                                    </select>
                                </Col>
                            </Row>
                        </Card.Header>
                        <Card.Body>
                            <Table striped bordered hover  responsive>
                                <thead>
                                <tr>
                                    <th>Id</th>
                                    <th>Patient</th>
                                    <th>Balance</th>
                                    <th>Last Updated Date</th>
                                </tr>
                                </thead>
                                <tbody>
                                {walletBalances?.content !=null && walletBalances.content.map(wb => {
                                        return <tr key={wb.walletId}>
                                            <td>{wb.walletId}</td>
                                            <td>{wb.patientName}</td>
                                            <td>&#8377; {wb.balance}</td>
                                            <td>{formatShortDateOnly(wb.lastUpdatedAt)}</td>
                                        </tr>
                                    }
                                )}
                                </tbody>
                            </Table>
                        </Card.Body>
                        <Card.Footer>
                            <Pagination
                                itemsPerPage={walletBalances?.size}
                                items={walletBalances?.totalElements}
                                totalPages ={walletBalances?.totalPages}
                                onPravious={onPrevious}
                                onNext={onNext}

                            />
                        </Card.Footer>
                    </Card>
                </Container>
            </Layout>

        </>
    )
}

export default WalletBalanceList;