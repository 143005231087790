import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import React, {useContext, useState} from "react";
import useHttpRequest from "../../hooks/useHttpRequest";
import AuthContext from "../../context/AuthContext";
import NotificationAlert from "../NotificationAlert/NotificationAlert";

const AddInvoice = (props) => {
    const { isLoading, error, sendRequest: addInvoice } = useHttpRequest();
    const { id, name, token, clinicId} = useContext(AuthContext)
    const [form, setForm] = useState({
         patientId: props.patientId,
         doctorId: id,
         consultationPrice : props.consultationPrice,
         prescriptionId : props.prescriptionId,
         medicinesPrice : props.medicinesPrice,
         labTestPrice : props.labTestPrice,
         productPrice : props.productPrice,
         deliveryCharge : props.deliveryCharge,
         discount : props.deliveryCharge,
         supplementPrice: props.supplementPrice,
         totalAmount : props.totalAmount,
         transactionId: props.transactionId,
        clinicId: clinicId
    })

    const handleResponse = (response) => {
        console.log(response);
        props.handleClose()
    }

    const onSubmitForm = (event) => {
        event.preventDefault();
        if(form.totalAmount != null){
            let url = '/invoice/add'
            if(props.invoiceId != undefined){
                url ='/invoice/'+ props.invoiceId+'/update'
            }
            addInvoice(
                { url: url,
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body : JSON.stringify(form)
                },
                handleResponse
            )

        }


    }

    const onInputChange = (event) => {
        setForm({
            ...form,
            [event.target.name]: event.target.value,
        });
    }


    return(
        <>
            <Modal size="lg" show={props.show} onHide={props.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Invoice </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {error &&
                    <NotificationAlert
                        variant="danger"
                        message={error}
                        heading="Error"
                        ttl="3000"
                        show="true"
                    />
                    }
                    <Form onSubmit={onSubmitForm}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Consultation Charge</Form.Label>
                            <Form.Control name="consultationPrice" onChange={onInputChange} value={form.consultationPrice} type="number" placeholder="" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Prescription Id</Form.Label>
                            <Form.Control name="prescriptionId" onChange={onInputChange} value={form.prescriptionId} type="number" placeholder="" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Medicines Charges</Form.Label>
                            <Form.Control name="medicinesPrice" onChange={onInputChange} value={form.medicinesPrice} type="number" placeholder="" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Lab Test Charges</Form.Label>
                            <Form.Control name="labTestPrice" onChange={onInputChange} value={form.labTestPrice} type="number" placeholder="" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label> Products Charges</Form.Label>
                            <Form.Control name="productPrice" onChange={onInputChange} value={form.productPrice} type="number" placeholder="" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label> Supplement Charges</Form.Label>
                            <Form.Control name="supplementPrice" onChange={onInputChange} value={form.supplementPrice} type="number" placeholder="" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Delivery Charges</Form.Label>
                            <Form.Control name="deliveryCharge" onChange={onInputChange} value={form.deliveryCharge} type="number" placeholder="" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Discount </Form.Label>
                            <Form.Control name="discount" onChange={onInputChange} value={form.discount} type="number" placeholder="" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Total</Form.Label>
                            <Form.Control onChange={onInputChange} name="totalAmount" value={form.totalAmount} type="number" placeholder="" />
                        </Form.Group>

                        <br/>
                        <Button variant="primary" type="submit">
                            Submit
                        </Button>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

        </>
    )
}


export default AddInvoice;