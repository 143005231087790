import React, {useContext, useEffect, useState} from 'react';
import {Card, Col, Container, Row} from "react-bootstrap";
import useHttpRequest from "../hooks/useHttpRequest";
import {faRupeeSign} from "@fortawesome/fontawesome-free-solid";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {UserContext, UserDispatchContext} from "../context/UserProvider";
import AuthContext from "../context/AuthContext";
import Layout from "./Layout";
import MonthlyIncomeChart from "../components/RealTimeData/MonthlyIncomeChart";
import MonthlyPatientVisitChart from "../components/RealTimeData/MonthlyPatientVisitChart";
import MonthlyPatientAcquisitionChart from "../components/RealTimeData/MonthlyPatientAcquisitionChart";

const Home = (props) => {
    const { id, name, token, clinicId} = useContext(AuthContext)
    const userDetails = React.useContext(UserContext);
    const setUserDetails = useContext(UserDispatchContext);
    const [incomeStats, setIncomeStats] = useState()
    const [patientVisitStats, setPatientVisitStats] = useState()
    const [patientAcquisitionStats, setPatientAcquisitionStats] = useState()


    const [stats, setStats] = useState({
        totalIncome:0,
        monthIncome:0,
        dayIncome:0,
        totalPatientCount:0,
        totalAppointmentCount:0,
        monthAppointmentCount:0,
        dayAppointmentCount:0,
        monthNewPatientCount:0,
        totalConsultationIncome:0,
        monthConsultationIncome:0,
        totalMedicineIncome:0,
        monthMedicineIncome:0,
        totalProductsIncome:0,
        monthProductsIncome:0,
    });
    const { isLoading, error, sendRequest: getClinicStats } = useHttpRequest();
    const {sendRequest: getIncomeChartStat } = useHttpRequest();
    const {sendRequest: getPatientVisitChartStat } = useHttpRequest();

    useEffect(() => {
        const transformClinicStats= (doctors) => {

            setStats(doctors);
        };
        const transformIncomeChartStat= (monthWiseIncome) => {

            setIncomeStats(monthWiseIncome);
        };
        const transformPatientVisitChartStat= (patintVisitStats) => {

            setPatientVisitStats(patintVisitStats);
        };
        const transformPatientAcquisitionChartStat= (data) => {

            setPatientAcquisitionStats(data);
        };
        getClinicStats(
            { url: '/clinic-stats?clinicId='+clinicId,
                method: "GET"
            },
            transformClinicStats
        );
        getIncomeChartStat(
            { url: '/clinic-stats/transactions-stats?clinicId='+clinicId,
                method: "GET"
            },
            transformIncomeChartStat
        );
        getPatientVisitChartStat(
            { url: '/clinic-stats/patient-visit-stats?clinicId='+clinicId,
                method: "GET"
            },
            transformPatientVisitChartStat
        );
        getPatientVisitChartStat(
            { url: '/clinic-stats/patient-acquisition-stats?clinicId='+clinicId,
                method: "GET"
            },
            transformPatientAcquisitionChartStat
        );
        return () => {
            // cleaning up the listeners here
        }
    }, []);


    return(
       <React.Fragment>
           <Layout>
           <br/>
           <Container>
               <Row>
                   <Col>
                       <Card className="shadow" bg="light">
                           <Card.Body>
                               <Card.Title>Total Income </Card.Title>
                              <h3><FontAwesomeIcon icon={faRupeeSign} /> {stats.totalIncome?.toLocaleString('en-IN') }</h3>
                           </Card.Body>
                       </Card>
                   </Col>
                   <Col>
                       <Card className="shadow" bg="light">
                           <Card.Body>
                               <Card.Title>Month Income </Card.Title>
                               <h3><FontAwesomeIcon icon={faRupeeSign} /> {stats.monthIncome?.toLocaleString('en-IN') }</h3>
                           </Card.Body>
                       </Card>
                   </Col>
                   <Col>
                       <Card className="shadow" bg="light">
                           <Card.Body>
                               <Card.Title>Day Income </Card.Title>
                               <h3><FontAwesomeIcon icon={faRupeeSign} /> {stats.dayIncome?.toLocaleString('en-IN') }</h3>
                           </Card.Body>
                       </Card>
                   </Col>
                   <Col>
                       <Card className="shadow" bg="light">
                           <Card.Body>
                               <Card.Title>Total Patient Count </Card.Title>
                               <h3> {stats.totalPatientCount}</h3>
                           </Card.Body>
                       </Card>
                   </Col>
               </Row>
               <br/>
               <Row>
                   <Col>
                       <Card className="shadow" bg="light">
                           <Card.Body>
                               <Card.Title>Total Appointments </Card.Title>
                               <h3>{stats.totalAppointmentCount}</h3>
                           </Card.Body>
                       </Card>
                   </Col>
                   <Col>
                       <Card className="shadow" bg="light">
                           <Card.Body>
                               <Card.Title>Month Appointments </Card.Title>
                               <h3>{stats.monthAppointmentCount}</h3>
                           </Card.Body>
                       </Card>
                   </Col>
                   <Col>
                       <Card className="shadow" bg="light">
                           <Card.Body>
                               <Card.Title>Day Appointments </Card.Title>
                               <h3>{stats.dayAppointmentCount}</h3>
                           </Card.Body>
                       </Card>
                   </Col>
                   <Col>
                       <Card className="shadow" bg="light">
                           <Card.Body>
                               <Card.Title>Month New Patient Count </Card.Title>
                               <h3>{stats.monthNewPatientCount}</h3>
                           </Card.Body>
                       </Card>
                   </Col>
               </Row>
               <br/>
               <Row>
                   <Col>
                       <Card className="shadow" bg="light">
                           <Card.Body>
                               <Card.Title>Total Consultation Income </Card.Title>
                               <h3><FontAwesomeIcon icon={faRupeeSign} /> {stats.totalConsultationIncome?.toLocaleString('en-IN') }</h3>
                           </Card.Body>
                       </Card>
                   </Col>
                   {/*<Col>*/}
                   {/*    <Card className="shadow" bg="light">*/}
                   {/*        <Card.Body>*/}
                   {/*            <Card.Title>Month Consultation Income </Card.Title>*/}
                   {/*            <h3><FontAwesomeIcon icon={faRupeeSign} /> {stats.monthConsultationIncome?.toLocaleString('en-IN') }</h3>*/}
                   {/*        </Card.Body>*/}
                   {/*    </Card>*/}
                   {/*</Col>*/}
                   <Col>
                       <Card className="shadow" bg="light">
                           <Card.Body>
                               <Card.Title>Total Meds Income </Card.Title>
                               <h3><FontAwesomeIcon icon={faRupeeSign} /> {stats.totalMedicineIncome?.toLocaleString('en-IN') }</h3>
                           </Card.Body>
                       </Card>
                   </Col>
                   <Col>
                       <Card className="shadow" bg="light">
                           <Card.Body>
                               <Card.Title>Month Meds Income </Card.Title>
                               <h3><FontAwesomeIcon icon={faRupeeSign} /> {stats.monthMedicineIncome?.toLocaleString('en-IN') }</h3>
                           </Card.Body>
                       </Card>
                   </Col>

               </Row>
               <br/>
               <Row>
                   <Col>
                       <Card className="shadow" bg="light">
                           <Card.Body>
                               <Card.Title>Total Products Income </Card.Title>
                               <h3><FontAwesomeIcon icon={faRupeeSign} /> {stats.totalProductsIncome?.toLocaleString('en-IN') }</h3>
                           </Card.Body>
                       </Card>
                   </Col>
                   <Col>
                       <Card className="shadow" bg="light">
                           <Card.Body>
                               <Card.Title>Month Products Income </Card.Title>
                               <h3><FontAwesomeIcon icon={faRupeeSign} /> {stats.monthProductsIncome?.toLocaleString('en-IN') }</h3>
                           </Card.Body>
                       </Card>
                   </Col>
               </Row>
               <br/>
               <Row>
                   <Col>
                       <Card className="shadow" bg="light">
                           <Card.Body>
                               <Card.Title>Total Supplement Income </Card.Title>
                               <h3><FontAwesomeIcon icon={faRupeeSign} /> {stats.totalSupplementIncome?.toLocaleString('en-IN') }</h3>
                           </Card.Body>
                       </Card>
                   </Col>
                   <Col>
                       <Card className="shadow" bg="light">
                           <Card.Body>
                               <Card.Title>Month Supplement Income </Card.Title>
                               <h3><FontAwesomeIcon icon={faRupeeSign} /> {stats.monthSupplementIncome?.toLocaleString('en-IN') }</h3>
                           </Card.Body>
                       </Card>
                   </Col>
               </Row>
               <br/>
               <Row>
                   <Col >
                       <MonthlyIncomeChart data={incomeStats}/>
                   </Col>
               </Row>
               <Row>
                   <Col>
                       <MonthlyPatientVisitChart data={patientVisitStats} />
                   </Col>
               </Row>
               <Row>
                   <Col>
                       <MonthlyPatientAcquisitionChart data={patientAcquisitionStats} />
                   </Col>
               </Row>
               {/*<Row>*/}
               {/*    <Col>*/}
               {/*        <Card style={{ width: '18rem' }}>*/}
               {/*            <Card.Img variant="top" src="https://img.icons8.com/color/96/000000/add-user-group-woman-man.png" />*/}
               {/*            <Card.Body style={{ textAlign: 'center' }}>*/}
               {/*                <Link variant="primary" to="/appointments">Appointments</Link>*/}
               {/*            </Card.Body>*/}
               {/*        </Card>*/}
               {/*    </Col>*/}
               {/*    <Col>*/}
               {/*        <Card style={{ width: '18rem' }}>*/}
               {/*            <Card.Img variant="top" src="https://img.icons8.com/color/96/000000/add-user-group-woman-man.png" />*/}
               {/*            <Card.Body style={{ textAlign: 'center' }} >*/}
               {/*                <Link variant="primary" to="/patients">Patients</Link>*/}
               {/*            </Card.Body>*/}
               {/*        </Card>*/}
               {/*    </Col>*/}
               {/*    <Col>*/}
               {/*        <Card style={{ width: '18rem' }}>*/}
               {/*            <Card.Img variant="top" src="https://img.icons8.com/fluent/96/000000/web-analystics.png" />*/}
               {/*            <Card.Body style={{ textAlign: 'center' }} >*/}
               {/*                <Link variant="primary" to="/finance/transactions">Finance</Link>*/}
               {/*            </Card.Body>*/}
               {/*        </Card>*/}
               {/*    </Col>*/}
               {/*    <Col>*/}

               {/*        <Card style={{ width: '18rem' }}>*/}
               {/*            <Card.Img variant="top" src=" https://img.icons8.com/color/96/000000/activity-history.png" />*/}
               {/*            <Card.Body style={{ textAlign: 'center' }} >*/}
               {/*                <Link variant="primary" to="/prescriptions">Prescriptions</Link>*/}
               {/*            </Card.Body>*/}
               {/*        </Card>*/}
               {/*    </Col>*/}
               {/*</Row>*/}
               {/*<br/>*/}
               {/*<Row>*/}
               {/*    <Col>*/}
               {/*        <Card style={{ width: '18rem' }}>*/}
               {/*            <Card.Img variant="top" src="https://img.icons8.com/fluent/96/000000/card-in-use.png" />*/}
               {/*            <Card.Body style={{ textAlign: 'center' }}>*/}
               {/*                <Link variant="primary" to="/invoices">Invoices</Link>*/}
               {/*            </Card.Body>*/}
               {/*        </Card>*/}
               {/*    </Col>*/}
               {/*    <Col></Col>*/}
               {/*    <Col></Col>*/}
               {/*    <Col></Col>*/}
               {/*</Row>*/}
           </Container>
           </Layout>
       </React.Fragment>

        // <h1>Manish</h1>
    )
}

export default Home;