import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import React, {useContext, useState} from "react";
import useHttpRequest from "../../hooks/useHttpRequest";
import AuthContext from "../../context/AuthContext";
import NotificationAlert from "../NotificationAlert/NotificationAlert";


const AddProduct = (props) =>{
    const inStock = ['Yes', 'No' ];
    const { isLoading, error, sendRequest: addPayment } = useHttpRequest();
    const { id, name, token, clinicId} = useContext(AuthContext)
    const [form, setForm] = useState({
        name:"",
        price:0,
        availableQuantity:0,
        clinicId: clinicId,
        inStock: true,
    });

    const handleResponse = (response) => {
        console.log(response);
        props.handleClose()
    }

    const onSubmitForm = (event) => {
        event.preventDefault();
        if(form.name !== undefined && form.price !== 0 ) {
            addPayment(
                {
                    url: '/products/add-product',
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(form)
                },
                handleResponse
            )
        }
    }

    const onInputChange = (event) => {
        setForm({
            ...form,
            [event.target.name]: event.target.value,
        });
    }

    const onInStockChange = (event) => {
        setForm({
            ...form,
            inStock: event.target.value === 'Yes',
        });
    }
    return(
        <>
            <Modal size="lg" show={props.show} onHide={props.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Product </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {error &&
                        <NotificationAlert
                            variant="danger"
                            message={error}
                            heading="Error"
                            ttl="3000"
                            show="true"
                        />
                    }
                    <Form onSubmit={onSubmitForm}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Name</Form.Label>
                            <Form.Control name="name" onChange={onInputChange} value={form.amount} type="text" placeholder="" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>In Stock</Form.Label>
                            <Form.Select  value={form.inStock} id="inStock" name="inStock" onChange={onInStockChange} aria-label="Default select example">
                                <option key={0}>Is In Stock ?</option>
                                {inStock.map((type) => (
                                    <option  key={type} value={type === 'Yes'}>{type}</option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Price</Form.Label>
                            <Form.Control name="price" onChange={onInputChange} value={form.price} type="number" placeholder="" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Available Quantity</Form.Label>
                            <Form.Control name="availableQuantity" onChange={onInputChange} value={form.availableQuantity} type="number" placeholder="" />
                        </Form.Group>
                        <br/>
                        <br/>
                        <Button variant="primary" type="submit">
                            Submit
                        </Button>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default AddProduct;