import React, {useContext, useEffect, useState} from "react";
import useHttpRequest from "../../hooks/useHttpRequest";
import {Card, Col, Container, Form, Row, Table} from "react-bootstrap";
import DatePicker from "react-datepicker";
import {formatDate} from "../../AppUtility";
import Pagination from "../Pagination/Pagination";
import Button from "react-bootstrap/Button";
import AddInvoice from "./AddInvoice";
import AuthContext from "../../context/AuthContext";
import Layout from "../../UI/Layout";


const InvoiceList = () => {
    const { id, name, token, clinicId} = useContext(AuthContext)
    const fromDate = new Date();
    fromDate.setDate(fromDate.getDate()-14)
    const [invoices, setInvoices] = useState(null);
    const [pageNumber, setPageNumber] = useState(0)
    const [dateRange, setDateRange] = useState([fromDate,new Date()]);
    const [startDate, endDate] =dateRange;
    const { isLoading, error, sendRequest: fetchInvoices } = useHttpRequest();
    const [showInvoiceModal, setShowInvoiceModal] = useState(false);
    const [editableInvoice, setEditableInvoice] = useState(null);

    useEffect(() => {
        const transformInvoices = (invoices) => {

            setInvoices(invoices);
        };

        if(startDate != null && endDate != null){
            const body  = {
                startDate: startDate.toISOString(),
                endDate: endDate.toISOString(),
                pageNumber:pageNumber,
                clinicId: clinicId
            }
            fetchInvoices(
                { url: '/invoices?'+Object.keys(body).map(key => key + '=' + body[key]).join('&'),
                    method: "GET"
                },
                transformInvoices
            );
        }
    }, [endDate, pageNumber]);

    const onChange = (dates) => {
        setDateRange(dates);
    };

    const onPrevious = () => {
        const updated = pageNumber
        setPageNumber(updated-1)
    }

    const onNext = () => {
        const updated = pageNumber
        setPageNumber(updated+1)
    }

    const handleCloseInvoiceModal = () => {
        setShowInvoiceModal(false);
    }

    const editInvoice = (event) =>{
        const editableInvoice = invoices?.content?.find(element => element.id == event.currentTarget.id);
        setEditableInvoice(editableInvoice);
        setShowInvoiceModal(true);
    }

    return(
        <>
            <Layout>
            <br/>
            <Container>
                <Card>
                    <Card.Header>
                        <Row>
                            <Col sm={8} xs={6} md={9}><h3>Invoices</h3></Col>
                            <Col sm={4} xs={6} md={3}>
                                <DatePicker
                                    customInput={<Form.Control />}
                                    selectsRange={true}
                                    startDate={startDate}
                                    endDate={endDate}
                                    onChange={onChange}
                                />
                            </Col>
                        </Row>
                    </Card.Header>
                    <Card.Body>
                        <Table striped bordered hover  responsive>
                            <thead>
                            <tr>
                                <th>Id</th>
                                <th>Patient</th>
                                <th>Amount</th>
                                <th>Created Date</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                            </thead>
                            <tbody>
                            {invoices?.content !=null && invoices.content.map(invoice => {
                                    return <tr key={invoice.id}>
                                        <td>{invoice.id}</td>
                                        <td>{invoice.patientName}</td>
                                        <td>&#8377; {invoice.totalAmount}</td>
                                        <td>{formatDate(invoice.createdAt)}</td>
                                        <td>{invoice.status}</td>
                                        <td>
                                            <Button id={invoice.id} onClick={editInvoice}>Edit</Button>
                                        </td>
                                    </tr>
                                }
                            )}
                            </tbody>
                        </Table>
                    </Card.Body>
                    <Card.Footer>
                        <Pagination
                            itemsPerPage={invoices?.size}
                            items={invoices?.totalElements}
                            totalPages ={invoices?.totalPages}
                            onPravious={onPrevious}
                            onNext={onNext}

                        />
                    </Card.Footer>
                </Card>
            </Container>
            {showInvoiceModal &&
            <AddInvoice
                show={showInvoiceModal}
                invoiceId={editableInvoice.id}
                consultationPrice={editableInvoice.consultationPrice}
                prescriptionId ={editableInvoice.prescriptionId}
                medicinesPrice = {editableInvoice.medicinesPrice}
                labTestPrice ={editableInvoice.labTestPrice}
                deliveryCharge = {editableInvoice.deliveryCharge}
                productPrice = {editableInvoice.productPrice}
                discount = {editableInvoice.deliveryCharge}
                totalAmount = {editableInvoice.totalAmount}
                transactionId = {editableInvoice.transactionId}
                supplementPrice = {editableInvoice.supplementPrice}
                patientId={editableInvoice.patientId}
                handleClose={handleCloseInvoiceModal} />
            }
            </Layout>
        </>
    )
}
;

export default InvoiceList;