import {Col, Row} from "react-bootstrap";

const VitalSigns = (props) => {
    return(
        <div>
            <Row>
                <Col>
                    <dl className="row">
                        <dt className="col-sm-6"><b>Weight :</b></dt>
                        <dd className="col-sm-6">{props.vitalSigns?.weight}</dd>
                    </dl>
                </Col>
                <Col>
                    <dl className="row">
                        <dt className="col-sm-6"><b>Bloog Pressure (mmHg) :</b></dt>
                        <dd className="col-sm-6">{props.vitalSigns?.bloodPressure}</dd>
                    </dl>
                </Col>
                <Col>
                    <dl className="row">
                        <dt className="col-sm-6"><b>Pulse(Heart beats/min) :</b></dt>
                        <dd className="col-sm-6">{props.vitalSigns?.heartBeatRate}</dd>
                    </dl>
                </Col>
                <Col>
                    <dl className="row">
                        <dt className="col-sm-6"><b>Temperature :</b></dt>
                        <dd className="col-sm-6">{props.vitalSigns?.tempreture}</dd>
                    </dl>
                </Col>
            </Row>
            <Row>
                <Col>
                    <dl className="row">
                        <dt className="col-sm-6"><b>Resp. Rate(Breaths/min) :</b></dt>
                        <dd className="col-sm-6">{props.vitalSigns?.respiratoryRate}</dd>
                    </dl>
                </Col>
                <Col>
                    <dl className="row">
                        <dt className="col-sm-6"><b>Height :</b></dt>
                        <dd className="col-sm-6">{props.vitalSigns?.height}</dd>
                    </dl>
                </Col>
                <Col>
                    <dl className="row">
                        <dt className="col-sm-6"><b>Muscle % :</b></dt>
                        <dd className="col-sm-6">{props.vitalSigns?.muscle}</dd>
                    </dl>
                </Col>
                <Col>
                    <dl className="row">
                        <dt className="col-sm-6"><b>Fat % :</b></dt>
                        <dd className="col-sm-6">{props.vitalSigns?.fat}</dd>
                    </dl>
                </Col>
            </Row>

            <Row>
                <Col>
                    <dl className="row">
                        <dt className="col-sm-6"><b>BMI :</b></dt>
                        <dd className="col-sm-6">{props.vitalSigns?.bmi}</dd>
                    </dl>
                </Col>
                <Col>
                    <dl className="row">
                        <dt className="col-sm-6"><b>Bone Weight  :</b></dt>
                        <dd className="col-sm-6">{props.vitalSigns?.boneWeight}</dd>
                    </dl>
                </Col>
                <Col>
                    <dl className="row">
                        <dt className="col-sm-6"><b>SPO2 :</b></dt>
                        <dd className="col-sm-6">{props.vitalSigns?.spo}</dd>
                    </dl>
                </Col>
                <Col>
                    <dl className="row">
                        <dt className="col-sm-6"><b>Blood Sugar :</b></dt>
                        <dd className="col-sm-6">{props.vitalSigns?.bloodSugar}</dd>
                    </dl>
                </Col>
            </Row>
            <Row>
                <Col>
                    <dl className="row">
                        <dt className="col-sm-6"><b>Fat Mass :</b></dt>
                        <dd className="col-sm-6">{props.vitalSigns?.fatMass}</dd>
                    </dl>
                </Col>
                <Col>
                    <dl className="row">
                        <dt className="col-sm-6"><b>Basal Metabolic :</b></dt>
                        <dd className="col-sm-6">{props.vitalSigns?.basalMetabolic}</dd>
                    </dl>
                </Col>
                <Col>
                    <dl className="row">
                        <dt className="col-sm-6"><b>Water Level :</b></dt>
                        <dd className="col-sm-6">{props.vitalSigns?.waterLevel}</dd>
                    </dl>
                </Col>
                <Col>
                    <dl className="row">
                        <dt className="col-sm-6"><b>Total Moisture :</b></dt>
                        <dd className="col-sm-6">{props.vitalSigns?.totalMoisture}</dd>
                    </dl>
                </Col>
            </Row>
            <Row>
                <Col>
                    <dl className="row">
                        <dt className="col-sm-6"><b>Fat Free Body Weight  :</b></dt>
                        <dd className="col-sm-6">{props.vitalSigns?.fatFreeBodyWeight}</dd>
                    </dl>
                </Col>
                <Col>
                    <dl className="row">
                        <dt className="col-sm-6"><b>Skeletal Muscle :</b></dt>
                        <dd className="col-sm-6">{props.vitalSigns?.skeletalMuscle}</dd>
                    </dl>
                </Col>
                <Col>
                    <dl className="row">
                        <dt className="col-sm-6"><b>Protein Mass :</b></dt>
                        <dd className="col-sm-6">{props.vitalSigns?.proteinMass}</dd>
                    </dl>
                </Col>
                <Col>
                    <dl className="row">
                        <dt className="col-sm-6"><b>Inorganic Salt :</b></dt>
                        <dd className="col-sm-6">{props.vitalSigns?.inorganicSalt}</dd>
                    </dl>
                </Col>
            </Row>
            <Row>
                <Col>
                    <dl className="row">
                        <dt className="col-sm-6"><b>Visceral Fat :</b></dt>
                        <dd className="col-sm-6">{props.vitalSigns?.visceralFat}</dd>
                    </dl>
                </Col>
                <Col>
                    <dl className="row">
                        <dt className="col-sm-6"><b>Bone Mineral Content :</b></dt>
                        <dd className="col-sm-6">{props.vitalSigns?.boneMineralContent}</dd>
                    </dl>
                </Col>
                <Col>
                    <dl className="row">
                        <dt className="col-sm-6"><b>Comprehensive :</b></dt>
                        <dd className="col-sm-6">{props.vitalSigns?.comprehensive}</dd>
                    </dl>
                </Col>
                <Col>
                    <dl className="row">
                        <dt className="col-sm-6"><b>Physical Age :</b></dt>
                        <dd className="col-sm-6">{props.vitalSigns?.physicalAge}</dd>
                    </dl>
                </Col>
            </Row>
            <Row>
                <Col>
                    <dl className="row">
                        <dt className="col-sm-6"><b>Blood Pressure Systolic :</b></dt>
                        <dd className="col-sm-6">{props.vitalSigns?.bloodPressureSystolic}</dd>
                    </dl>
                </Col>
                <Col>
                    <dl className="row">
                        <dt className="col-sm-6"><b>Blood Pressure Diastolic :</b></dt>
                        <dd className="col-sm-6">{props.vitalSigns?.bloodPressureDiastolic}</dd>
                    </dl>
                </Col>
                <Col>

                </Col>
                <Col>

                </Col>
            </Row>

        </div>
    )
}

export default VitalSigns;