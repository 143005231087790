import React, {useContext, useState} from "react";
import {Col, Form, ProgressBar, Row} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import axiosInstance from "../../utils/Axios";
import Modal from "react-bootstrap/Modal";
import AuthContext from "../../context/AuthContext";


const UploadFile = (props) => {
    const authCtx = useContext(AuthContext);
    const [selectedFiles, setSelectedFiles] = useState()
    const [progress, setProgress] = useState()

    const submitHandler = e => {
        e.preventDefault() //prevent the form from submitting
        let formData = new FormData()

        formData.append("file", selectedFiles[0])
        axiosInstance.post("/patients/"+props.patientId+"/file", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization" : "Bearer"+" "+ authCtx.token,

            },
            onUploadProgress: data => {
                //Set the progress value to show the progress bar
                const progress = Math.round((100 * data.loaded) / data.total)
                setProgress(progress)
                if(progress ===100){
                    props.handleClose()
                }
            },
        })
    }


    return (
        <>
            <Modal size="lg" show={props.show} onHide={props.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add File </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col lg={{ span: 4, offset: 3 }}>
                            <Form
                                action="/upload/file"
                                method="post"
                                encType="multipart/form-data"
                                onSubmit={submitHandler}
                            >
                                <Form.Group controlId="formFile" className="mb-3">
                                    <Form.Label>Choose File</Form.Label>
                                    <Form.Control type="file"  onChange={e => {
                                        setSelectedFiles(e.target.files)
                                    }} />
                                </Form.Group>
                                <Form.Group>
                                    <Button variant="info" type="submit">
                                        Upload
                                    </Button>
                                </Form.Group>
                                {progress && <ProgressBar now={progress} label={`${progress}%`} />}
                            </Form>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>

        </>
    );

}

export default UploadFile;