import {ButtonGroup, Card, Col, Container, Row, Table} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useEffect, useState} from "react";
import {faInbox, faMobilePhone} from "@fortawesome/free-solid-svg-icons";
import {useNavigate} from "react-router-dom";
import {useParams} from "react-router";
import useHttpRequest from "../../hooks/useHttpRequest";
import Layout from "../../UI/Layout";
import AddHealthCardModal from "../HealthCard/AddHealthCardModal";
import {formatDate} from "../../AppUtility";
import DeactivateHealthCardModal from "../HealthCard/DeactivateHealthCardModal";


const PatientProfile = (props) => {
    let navigate = useNavigate();
    let {id} = useParams();
    const [profileData, setProfileData] = useState(null);
    const [refresh, setRefresh] = useState(false);
    const [showHealthCardModal, setShowHealthCardModal] = useState(false);
    const [showDecHealthCardModal, setShowDecHealthCardModal] = useState(false);
    const { sendRequest: fetchPatientProfileData } = useHttpRequest();
    const [healthCardId, setHealthCardId] = useState(null);


    useEffect(() => {

        const mapPatientProfileData = (profileData) => {
            setProfileData(profileData);
        };

        if(id !== null && id !== undefined){
            fetchPatientProfileData(
                { url: '/patients/'+id+'/profile'},
                mapPatientProfileData
            );
        }


    }, [id, refresh]);

    const gotoPatientFormPage = (event) =>{
        let path = "/patients/add-patient/";
        navigate(path);
    }
    const gotoPatientEditFormPage = (event) =>{
        let path = "/patients/update-patient/"+event.target.id;
        navigate(path);
    }


    const gotoPatientVisitPage = (event) =>{
        let path = "/patients/visits/"+event.target.id;
        navigate(path);
    }

    const addMember= (event) =>{
        let path = "/patients/add-member/"+event.target.id;
        navigate(path);
    }

    const gotoPatientProfilePage = (event) =>{
        let path = "/patients/profile/"+event.target.id;
        navigate(path);
    }

    const renderImage = (gender) => {
        if(gender === 'Female'){
            return "/img/girl.png"
        }
        return "/img/male-profile.png"
    }

    const addHealthCard = (event) => {
        setShowHealthCardModal(true);
    }

    const cancelHealthCard = (event) => {
        setHealthCardId(event.target.id)
        setShowDecHealthCardModal(true);
    }

    const handleCloseHealthCardModal = () => {
        setShowHealthCardModal(false);
        setRefresh(!refresh)
    }

    const handleCloseCancelHealthCardModal = ()  => {
        setShowDecHealthCardModal(false);
        setRefresh(!refresh)
    }

    return(
        <>
            <Layout>
                <br/>
                <Container>
                    <Card>
                        <Card.Header>
                            <Row>
                                <Col xs={10}><h3>Patient Profile</h3></Col>
                                <Col>
                                    <Button onClick={gotoPatientFormPage}>Add New Patient</Button>
                                </Col>
                            </Row>
                        </Card.Header>
                        <Card.Body>
                            <Row>
                                <Col xs={12} md={4}>
                                    <Card >
                                        <Card.Body>
                                            <Row>
                                                <Col xs={4}>
                                                    <img src={renderImage(profileData?.patientInfo?.gender)}/>
                                                </Col>
                                                <Col xs={8}>
                                                    <Card.Title>{profileData?.patientInfo?.name}</Card.Title>
                                                    <Card.Text>
                                                        <FontAwesomeIcon icon={faMobilePhone} /> {profileData?.patientInfo?.mobileNumber}
                                                    </Card.Text>
                                                    <Card.Text>
                                                        <FontAwesomeIcon icon={faInbox} /> {profileData?.patientInfo?.emailId}
                                                    </Card.Text>
                                                </Col>
                                            </Row>
                                            <br/>
                                            <ButtonGroup size="md">
                                                <Button variant="primary" id={profileData?.patientInfo?.id} onClick={gotoPatientEditFormPage}>Edit Profile</Button>
                                                <Button variant="primary" id={profileData?.patientInfo?.id} onClick={addMember}>Add Member</Button>
                                                <Button variant="primary" id={profileData?.patientInfo?.id} onClick={gotoPatientVisitPage}>Visit History</Button>
                                            </ButtonGroup>

                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col xs={12} md={8}>

                                    <Card >
                                        <Card.Header>
                                            <Row>
                                                <Col md={9} xs={10}>Health Cards</Col>
                                                <Col md={3}><Button onClick={addHealthCard}>Add HealthCard</Button></Col>

                                            </Row>
                                        </Card.Header>
                                        <Card.Body>
                                            {profileData?.healthCards?.length >0  &&
                                                <Table striped bordered hover  responsive>
                                            <thead>
                                            <tr>
                                                <th>Id</th>
                                                <th>Type</th>
                                                <th>Start Date</th>
                                                <th>End Date</th>
                                                <th>Comment</th>
                                                <th>Status</th>
                                                <th>Action</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {profileData?.healthCards.map(card => {
                                                    return <tr key={card.id}>
                                                        <td>{card.id}</td>
                                                        <td>{card.type}</td>
                                                        <td>{formatDate(card.subscriptionStartDate)}</td>
                                                        <td>{formatDate(card.subscriptionEndDate)}</td>
                                                        <td>{card.comment}</td>
                                                        <td>{card.active ? 'Active' : 'Deactivated'}</td>
                                                        <td>
                                                            <Button id={card.id}
                                                                    onClick={cancelHealthCard}>Deactivate</Button>
                                                        </td>
                                                    </tr>
                                                }
                                            )}
                                            </tbody>
                                        </Table>
                                            }

                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    <br/>
                    <br/>
                    {profileData?.relatedPatient?.size > 0 &&
                    <Card>
                        <Card.Header>
                            <Row>
                                <Col><h3>Related Patients</h3></Col>

                            </Row>
                        </Card.Header>
                        <Card.Body>
                            <Row>
                                {profileData?.relatedPatient?.map(patient => {
                                    return (
                                        <Col key={patient?.id} xs={12} md={4}>
                                            <Card >
                                                <Card.Body>
                                                    <Row>
                                                        <Col xs={4}>
                                                            <img src={renderImage(patient?.gender)}/>
                                                        </Col>
                                                        <Col xs={8}>
                                                            <Card.Title>{patient?.name}</Card.Title>
                                                            <Card.Text>
                                                                <FontAwesomeIcon icon={faMobilePhone} /> {patient?.mobileNumber}
                                                            </Card.Text>
                                                            <Card.Text>
                                                                <FontAwesomeIcon icon={faInbox} /> {patient?.emailId}
                                                            </Card.Text>
                                                        </Col>
                                                    </Row>
                                                    <br/>
                                                    <ButtonGroup size="md">
                                                        <Button variant="primary" id={patient?.id} onClick={gotoPatientProfilePage}>Profile</Button>
                                                        <Button variant="primary"  id={patient?.id} onClick={gotoPatientEditFormPage}>Edit Profile</Button>
                                                        <Button variant="primary" id={patient?.id} onClick={gotoPatientVisitPage}>Visit History</Button>
                                                    </ButtonGroup>

                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    )
                                })}

                            </Row>
                        </Card.Body>
                    </Card>
                    }



                </Container>
            </Layout>
            {showHealthCardModal &&
            <AddHealthCardModal
                show={showHealthCardModal}
                patientId={id}
                handleClose={handleCloseHealthCardModal}
            />
            }
            {showDecHealthCardModal &&
            <DeactivateHealthCardModal
                show={showDecHealthCardModal}
                id={healthCardId}
                handleClose={handleCloseCancelHealthCardModal}
            />
            }
        </>


    )
}

export default PatientProfile;