import React from 'react';
import {
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip,
} from 'chart.js';
import {Line} from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);



const MonthlyPatientVisitChart = (props) => {
    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Patient Chart',
            },
        },
    };
    const labels = props?.data?.allPatientVisits?.map(d => d.month+ "/"+ d.year);

    const data = {
        labels,
        datasets: [
            {
                label: 'All Patient Visit',
                data: props?.data?.allPatientVisits?.map(d => d.count),
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
            },
            {
                label: 'New Patient Visit',
                data: props?.data?.newPatientVisits?.map(d => d.count),
                borderColor: 'rgb(53, 162, 235)',
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
            },
        ],
    };

    return <Line options={options} data={data} />;
}

export default MonthlyPatientVisitChart;