import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import {Col, Row} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import React, {useContext, useEffect, useState} from "react";
import useHttpRequest from "../../hooks/useHttpRequest";
import AuthContext from "../../context/AuthContext";
import NotificationAlert from "../NotificationAlert/NotificationAlert";


const Order = (props) => {
    const { id, name, token, clinicId} = useContext(AuthContext)
    const item = {
        id:"",
        quantity:1
    }
    let items = [item]
    if(props.items !== undefined){
        items = props.items;
    }
    const [orderItems, setOrderItems] = useState(items);
    const [products, setProducts] = useState([]);
    const { isLoading, error, sendRequest: createOrder } = useHttpRequest();
    const {sendRequest: getProducts } = useHttpRequest();

    useEffect(() => {
        const transformProducts= (items) => {

            setProducts(items);
        };
        getProducts(
            { url:  '/products?pageNumber=0&size=50&clinicId='+clinicId,
                method: "GET"
            },
            transformProducts
        );


    }, [id]);

    const addMoreHandler = () => {
        setOrderItems([
            ...orderItems,
            item
        ]);
    }

    const handleResponse = (response) => {
        console.log(response);
        props.handleClose()
    }

    const onSubmitForm = (event) => {
        event.preventDefault();
        let url = '/orders/add';
        // if(props.prescriptionId !== undefined) {
        //     url = '/prescriptions/' + props.prescriptionId + '/update'
        // }
        if(orderItems[0].id != null) {
            createOrder(
                {
                    url: url,
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        patientId:props.patientId,
                        doctorId:id,
                        items: orderItems,
                        clinicId: clinicId,
                        appointmentId: props.appointmentId
                    })
                },
                handleResponse
            )
        }
    }

    const onInputChange = (event) => {

        const newOrder = [...orderItems];
        newOrder[event.target.attributes.index.value][event.target.name]= event.target.value;
        setOrderItems(newOrder);

    }

    const onSelectProduct = (event) => {

        const newOrder = [...orderItems];
        newOrder[event.target.attributes.index.value][event.target.name]= event.target.value;
        setOrderItems(newOrder);

    }
    return(
        <>
            <Modal size="lg" show={props.show} onHide={props.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Order </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {error &&
                        <NotificationAlert
                            variant="danger"
                            message={error}
                            heading="Error"
                            ttl="3000"
                            show="true"
                        />
                    }
                    <Form onSubmit={onSubmitForm}>
                        {orderItems?.map((item, index)=>{
                            return <Row key={index}>
                                <Col>
                                    {/*<Form.Group className="mb-3" controlId="formBasicEmail">*/}
                                    {/*    <Form.Label>Name</Form.Label>*/}
                                    {/*    <Form.Control index={index} name="name" onChange={onInputChange} value={item.name} type="text" placeholder="" />*/}
                                    {/*</Form.Group>*/}
                                    <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                        <Form.Label>Name</Form.Label>
                                        <Form.Select onChange={onSelectProduct} index={index}  name="id" aria-label="Default select example">
                                            <option key={0}>Select Product</option>
                                            {products && products?.content?.map(prd => {
                                                return <option  key={prd.id} value={prd.id}>{prd.name}</option>
                                            })}

                                        </Form.Select>
                                    </Form.Group>
                                </Col>

                                <Col>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>Quantity</Form.Label>
                                        <Form.Control index={index} name="quantity" onChange={onInputChange} value={item.quantity} type="text" placeholder="" />
                                    </Form.Group>
                                </Col>

                            </Row>
                        })}
                        <Row>
                            <Col>
                                <Button onClick={addMoreHandler} variant="primary" type="button">
                                    Add More
                                </Button>
                            </Col>

                        </Row>
                        <br/>
                        <br/>

                        <Button variant="primary" type="submit">
                            Submit
                        </Button>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default Order;