import {Alert, Badge, ButtonGroup, Card, Col, Container, DropdownButton, Figure, Row} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Dropdown from 'react-bootstrap/Dropdown';
import React, {useContext, useEffect, useState} from "react";
import useHttpRequest from "../../hooks/useHttpRequest";
import {useParams} from "react-router";
import VitalSigns from "../VitalSigns/VitalSigns";
import Pagination from "../Pagination/Pagination";
import Prescription from "../Prescription/Prescription";
import File from "../Files/File";
import ClinicalNote from "../ClinicalNote/ClinicalNote";
import {useNavigate} from "react-router-dom";
import AddAppointmentModal from "../Appointment/AddAppointmentModal";
import AddClinicalNote from "../ClinicalNote/AddClinicalNote";
import {formatDate} from "../../AppUtility";
import AddVitalSigns from "../VitalSigns/AddVitalSigns";
import AddPrescription from "../Prescription/AddPrescription";
import AddInvoice from "../Invoice/AddInvoice";
import PatientTransactionsList from "../Transaction/PatientTransactionsList";
import AddPayment from "../Transaction/AddPayment";
import AuthContext from "../../context/AuthContext";
import Layout from "../../UI/Layout";
import UploadFile from "../Files/UploadFile";
import NotificationAlert from "../NotificationAlert/NotificationAlert";
import Order from "../Order/Order";


const PatientVisitHistoryTimeline = (props) => {
    let { id } = useParams();
    let navigate = useNavigate();
    const {name, token, clinicId} = useContext(AuthContext);
    const [refresh, setRefresh] = useState(false);
    const [appointments, setAppointments] = useState(null);
    const [appointmentId, setAppointmentId] = useState(null);
    //todo: remove this doctor id whn implemnt login authntication to gt doctor id
    const [doctorId, setDoctorId] = useState(null);
    const [pageNumber, setPageNumber] = useState(0)
    const [patient, setPatient] = useState({});
    const [doctors, setDoctors] = useState(null);
    const [showAppointmentModal, setShowAppointmentModal] = useState(false);
    const [showClinicalNotesModal, setShowClinicalNotesModal] = useState(false);
    const [showVitalSignsModal, setShowVitalSignsModal] = useState(false);
    const [showProductOrderModal, setShowProductOrderModal] = useState(false);
    const [showPrescriptionModal, setShowPrescriptionModal] = useState(false);
    const [showInvoiceModal, setShowInvoiceModal] = useState(false);
    const [showPassBookModal, setShowPassBookModal] = useState(false);
    const [showPaymentModal, setShowPaymentModal] = useState(false);
    const [showAddFileModal, setShowAddFileModal] = useState(false);
    const { isLoading, error, sendRequest: fetchAppointments } = useHttpRequest();
    const { sendRequest: fetchPatientBasicInfo } = useHttpRequest();
    const { sendRequest: fetchDoctors } = useHttpRequest();

    useEffect(() => {
        const transformAppointments = (appointments) => {

            setAppointments(appointments);
        };

            fetchAppointments(
                { url: '/patients/'+id+'/appointments?pageNumber='+pageNumber},
                transformAppointments
            );
    }, [pageNumber, refresh]);


    //todo: move to redux
    useEffect(() => {
        const mapPatient = (patient) => {

            setPatient(patient);
        };

        if(id !== null && id !== undefined){
            fetchPatientBasicInfo(
                { url: '/patients/'+id},
                mapPatient
            );
        }

        const transformDoctors= (doctors) => {

            setDoctors(doctors);
        };
        //todo movee this call to eithr individual modal like in appointment modal or rmovee thier
        fetchDoctors(
            { url: '/clinics/'+clinicId+'/doctors',
                method: "GET"
            },
            transformDoctors
        );


    }, [id, refresh]);


    const onPrevious = () => {
        const updated = pageNumber
        setPageNumber(updated-1)
    }

    const onNext = () => {
        const updated = pageNumber
        setPageNumber(updated+1)
    }


    const gotoPatientProfilePage = (event) =>{
        let path = "/patients/profile/"+event.target.id;
        navigate(path);
    }

    const renderImage = (gender) => {
        if(gender === 'Female'){
            return "/img/girl.png"
        }
        return "/img/boy.png"
    }


    const addAppointment = () => {
        setShowAppointmentModal(true);
    }

    const handleCloseAppointmentModal = () => {
        setShowAppointmentModal(false);
        setRefresh(!refresh)
    }

    const addClinicalNotes = (event) => {
        setAppointmentId(event.currentTarget.id)
        setShowClinicalNotesModal(true);
    }

    const handleCloseClinicalNotesModal = () => {
        setShowClinicalNotesModal(false);
        setRefresh(!refresh)
    }

    const addVitalSigns = (event) => {
        setAppointmentId(event.currentTarget.id)
        setShowVitalSignsModal(true);
    }
    const orderProducts = (event) => {
        setAppointmentId(event.currentTarget.id)
        setShowProductOrderModal(true);
    }

    const handleCloseVitalSignsModal = () => {
        setShowVitalSignsModal(false);
        setRefresh(!refresh)
    }
    const addPrescription = (event) => {
        setAppointmentId(event.currentTarget.id)
        setDoctorId(event.currentTarget.attributes.doctorId.value)
        setShowPrescriptionModal(true);
    }

    const handleClosePrescriptionModal = () => {
        setShowPrescriptionModal(false);
        setRefresh(!refresh)
    }
    const handleCloseOrderProductsModal = () => {
        setShowProductOrderModal(false);
        setRefresh(!refresh)
    }

    const addInvoice = (event) => {
        setShowInvoiceModal(true);
    }

    const handleCloseInvoiceModal = () => {
        setShowInvoiceModal(false);
        setRefresh(!refresh)
    }

    const getPassBookHandler = (event) => {
        setShowPassBookModal(true);
    }

    const handleClosePassBookModal = () => {
        setShowPassBookModal(false);
        setRefresh(!refresh)
    }

    const getPaymentModalHandler = (event) => {
        setShowPaymentModal(true);
    }

    const handleClosePaymentModal = () => {
        setShowPaymentModal(false);
        setRefresh(!refresh)
    }

    const getAddFileModalHandler = () => {
        setShowAddFileModal(true);
    }

    const handleCloseAddFileModal = () => {
        setShowAddFileModal(false);
        setRefresh(!refresh)
    }


    return(
        <div>
            <Layout>
            <br/>
            <Container>
                {error &&
                <NotificationAlert
                    variant="danger"
                    message={error}
                    heading="Error"
                    ttl="3000"
                    show="true"
                />
                }
                <Card>
                    <Card.Header>
                        <Row>
                            <Col xs={9}>
                                <Figure>
                                    <Figure.Image
                                        alt="171x180"
                                        src={renderImage(patient.gender)}
                                    />
                                    <Figure.Caption>
                                        <h4>{patient.name}</h4>
                                        <p style={{fontSize: "1rem"}}>ID: {patient.id}  </p>
                                        <span style={{fontSize: "1rem"}}>{patient.mobileNumber}</span>
                                        <p style={{fontSize: "1rem"}}>{patient.emailId}</p>
                                    </Figure.Caption>
                                </Figure>
                            </Col>
                            <Col xs={3}>
                                <br/>
                                <ButtonGroup size="sm"  className="mb-2 pull-right">
                                    <Button variant="primary"
                                            onClick={addAppointment}
                                            className="text-white">
                                        Add Appointment
                                    </Button>
                                    <Button variant="primary" onClick={addInvoice} className="text-white">Billing</Button>
                                </ButtonGroup>
                                <br/>
                                <ButtonGroup size="sm" className="mb-2">
                                    <Button variant="primary" onClick={getPassBookHandler} className="text-white">Pass Book</Button>
                                    <Button variant="primary" onClick={getPaymentModalHandler} className="text-white">Add Payment</Button>
                                </ButtonGroup>
                                <ButtonGroup size="sm" className="mb-2">
                                    <Button variant="primary" id={id} onClick={gotoPatientProfilePage}>Profile</Button>
                                    <Button variant="primary" onClick={getAddFileModalHandler}  className="text-white">Add File</Button>
                                </ButtonGroup>
                                <br/>
                                <h4>Balance : &#8377; {patient?.walletBalance}</h4>
                            </Col>
                        </Row>

                    </Card.Header>
                    <Card.Body>
                        {appointments?.content?.length<=0 &&

                        <Alert  variant="light">
                            <h3>No Appointments, Please create one !</h3>
                        </Alert>
                        }

                        {appointments?.content?.length> 0  && appointments.content.map(app => {
                           return <div key={app.id}>
                               <Card >
                                <Card.Header>
                                    <Row>
                                        <Col xs={3}><img src="/img/agenda.png" alt=""/> {formatDate(app.startTime)}</Col>
                                        <Col xs={7} ># {app.id} Appointment with DR. {app.doctorName}</Col>
                                        <Col xs={2} >
                                            <DropdownButton className="end-0" id="dropdown-basic-button" title="Actions">
                                                <Dropdown.Item id={app.id} doctorId={app.doctorId} onClick={addPrescription}>Prescription</Dropdown.Item>
                                                <Dropdown.Item id={app.id} onClick={addClinicalNotes}>Clinical Notes</Dropdown.Item>
                                                <Dropdown.Item id={app.id} onClick={addVitalSigns}>Vital Signs</Dropdown.Item>
                                                <Dropdown.Item id={app.id} onClick={orderProducts}>Order Products</Dropdown.Item>
                                            </DropdownButton>
                                        </Col>
                                    </Row>
                                </Card.Header>
                                <Card.Body>
                                    {app.vitalSignsList.length>0 &&
                                        <Card>
                                            <Card.Header>Vital Signs</Card.Header>
                                            <Card.Body>
                                                <VitalSigns vitalSigns={app.vitalSignsList[0]}/>
                                            </Card.Body>
                                        </Card>
                                    }
                                    <br/>
                                    {app.prescriptions.length>0 &&
                                        <Card>
                                            <Card.Header>Prescription <Badge bg="primary">{app.prescriptions[0]?.id}</Badge></Card.Header>
                                            <Card.Body>
                                                <Prescription prescription={app.prescriptions[0]}/>
                                            </Card.Body>
                                        </Card>
                                    }
                                    <br/>
                                    {app.files.length > 0 &&
                                    <Card >
                                        <Card.Header> Files</Card.Header>
                                        <Card.Body>
                                            <File files={app.files}/>
                                        </Card.Body>
                                    </Card>
                                    }
                                    <br/>
                                    {app.clinicalNotes.length > 0 &&
                                    <Card>
                                        <Card.Header> Clinical Notes</Card.Header>
                                        <Card.Body>
                                            <ClinicalNote notes={app.clinicalNotes}/>
                                        </Card.Body>
                                    </Card>
                                    }

                                </Card.Body>
                            </Card>
                               <br/>
                           </div>
                        })}


                    </Card.Body>
                    <Card.Footer>
                        <Pagination
                            itemsPerPage={appointments?.size}
                            items={appointments?.totalElements}
                            totalPages ={appointments?.totalPages}
                            onPravious={onPrevious}
                            onNext={onNext}

                        />
                    </Card.Footer>
                </Card>

            </Container>
            </Layout>
            {showAppointmentModal &&
                <AddAppointmentModal
                    show={showAppointmentModal}
                    patientId={id}
                    doctors={doctors}
                    handleClose={handleCloseAppointmentModal}
                />
            }
            {showClinicalNotesModal &&
                <AddClinicalNote
                    show={showClinicalNotesModal}
                    patientId={id}
                    appointmentId={appointmentId}
                    handleClose={handleCloseClinicalNotesModal} />
            }
            {showVitalSignsModal &&
            <AddVitalSigns
                show={showVitalSignsModal}
                patientId={id}
                appointmentId={appointmentId}
                handleClose={handleCloseVitalSignsModal} />
            }
            {showPrescriptionModal &&
            <AddPrescription
                show={showPrescriptionModal}
                patientId={id}
                appointmentId={appointmentId}
                handleClose={handleClosePrescriptionModal} />
            }
            {showInvoiceModal &&
            <AddInvoice
                show={showInvoiceModal}
                patientId={id}
                handleClose={handleCloseInvoiceModal} />
            }
            {showPassBookModal &&
            <PatientTransactionsList
                show={showPassBookModal}
                patientId={id}
                balance={patient?.walletBalance}
                handleClose={handleClosePassBookModal} />
            }
            {showPaymentModal &&
            <AddPayment
                show={showPaymentModal}
                patientId={id}
                handleClose={handleClosePaymentModal} />
            }
            {showAddFileModal &&
            <UploadFile
                show={showAddFileModal}
                patientId={id}
                handleClose={handleCloseAddFileModal} />
            }
            {showProductOrderModal &&
                <Order
                    show={showProductOrderModal}
                    patientId={id}
                    appointmentId={appointmentId}
                    handleClose={handleCloseOrderProductsModal} />
            }

        </div>


    )

}

export default PatientVisitHistoryTimeline;