import Header from "../common/Header";
import {Card, Col, Container, Row, Table} from "react-bootstrap";
import Pagination from "../Pagination/Pagination";
import React, {useEffect, useState} from "react";
import useHttpRequest from "../../hooks/useHttpRequest";
import AddDoctorModal from "./AddDoctorModal";


const DoctorList = () => {
    const [doctors, setDoctors] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [pageNumber, setPageNumber] = useState(0)
    const { isLoading, error, sendRequest: fetchDoctors } = useHttpRequest();

    useEffect(() => {
        const transformDoctors = (doctors) => {

            setDoctors(doctors);
        };


            const body  = {
                pageNumber:pageNumber
            }
        fetchDoctors(
                { url: '/doctors?'+Object.keys(body).map(key => key + '=' + body[key]).join('&'),
                    method: "GET"
                    // headers: {
                    //     'Content-Type': 'application/json'
                    // }
                },
                transformDoctors
            );

    }, [pageNumber]);

    const onPrevious = () => {
        const updated = pageNumber
        setPageNumber(updated-1)
    }

    const onNext = () => {
        const updated = pageNumber
        setPageNumber(updated+1)
    }

    const addDoctor = () => {
        setShowModal(true);
    }

    const handleClosModal = () => {
        setShowModal(false);
    }
    return (
        <>
            <Header/>
            <br/>
            <Container>
                <Card>
                    <Card.Header>
                        <Row>
                            <Col  xs={10}>
                                <h3>Doctors</h3>
                            </Col>

                            <Col xs={2}>
                                <button type="button" onClick={addDoctor} className="btn btn-primary">Add Doctor</button>
                            </Col>
                        </Row>
                    </Card.Header>
                    <Card.Body>
                        <Table striped bordered hover  responsive>
                            <thead>
                            <tr>
                                <th>id</th>
                                <th>Name</th>
                                <th>Email Id</th>
                                <th>Number of Clinics</th>
                                <th>Actions</th>
                            </tr>
                            </thead>
                            <tbody>
                            {doctors?.content !=null && doctors.content.map(doc => {
                                    return <tr key={doc.id}>
                                        <td>{doc.id}</td>
                                        <td>Dr. {doc.name}</td>
                                        <td>{doc.emailId}</td>
                                        <td>{doc.numberOfClinics}</td>
                                        <td></td>
                                    </tr>
                                }
                            )}
                            </tbody>
                        </Table>
                    </Card.Body>
                    <Card.Footer>
                        <Pagination
                            itemsPerPage={doctors?.size}
                            items={doctors?.totalElements}
                            totalPages ={doctors?.totalPages}
                            onPravious={onPrevious}
                            onNext={onNext}

                        />
                    </Card.Footer>
                </Card>

            </Container>
            <AddDoctorModal show={showModal}  doctors={doctors} handleClose={handleClosModal} />
        </>
    )
}
export default DoctorList;