import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import React from "react";
import useHttpRequest from "../../hooks/useHttpRequest";
import NotificationAlert from "../NotificationAlert/NotificationAlert";

const CancelPayment = (props) =>{
    const { isLoading, error, sendRequest: cancelPayment } = useHttpRequest();

    //     patientId:props.patientId,
    //     amount:0,
    //     paymentMode:"",
    //     comment:"",
    //     doctorId:id,
    //     clinicId: clinicId
    // });

    const handleResponse = (response) => {
        console.log(response);
        props.handleClose()
    }

    const onSubmitForm = (event) => {
        event.preventDefault();
        if(props.transactionId !== undefined) {
            cancelPayment(
                {
                    url: '/finance/transactions/cancel/'+props.transactionId,
                    method: "POST"
                },
                props.handleClose()
            )
        }
    }

    // const onInputChange = (event) => {
    //     setForm({
    //         ...form,
    //         [event.target.name]: event.target.value,
    //     });
    // }

    // const onSelectPaymentMode = (event) => {
    //     setForm({
    //         ...form,
    //         paymentMode: event.target.value,
    //     });
    // }

    return(
        <>
            <Modal size="lg" show={props.show} onHide={props.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Cancel Payment </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {error &&
                        <NotificationAlert
                            variant="danger"
                            message={error}
                            heading="Error"
                            ttl="3000"
                            show="true"
                        />
                    }
                    <p>Are you sure you want to cancel Payment</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={onSubmitForm}>
                        Yes
                    </Button>
                    <Button variant="secondary" onClick={props.handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
export default CancelPayment;