import {Card, Col, Container, Form, Row, Table} from "react-bootstrap";
import Pagination from "../Pagination/Pagination";
import React, {useContext, useEffect, useState} from "react";
import useHttpRequest from "../../hooks/useHttpRequest";
import DatePicker from "react-datepicker";
import {formatShortDateOnly} from "../../AppUtility";
import AuthContext from "../../context/AuthContext";
import Layout from "../../UI/Layout";
import Button from "react-bootstrap/Button";
import CancelPayment from "./CancelPayment";


const TransactionList = () => {
    const { id, name, token, clinicId} = useContext(AuthContext)
    const fromDate = new Date();
    fromDate.setDate(fromDate.getDate()-14)
    const [transactions, setTransactions] = useState(null);
    const [pageNumber, setPageNumber] = useState(0)
    const [dateRange, setDateRange] = useState([fromDate, new Date()]);
    const [startDate, endDate] =dateRange;
    const { isLoading, error, sendRequest: fetchTransactions } = useHttpRequest();
    const [showCancelTransactionModal, setShowCancelTransactionModal] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const  [selectedTransactionId, setSelectedTransationId]  = useState(undefined);

    useEffect(() => {
        const transformTransactions = (transacctions) => {

            setTransactions(transacctions);
        };

        if(startDate != null && endDate != null){
            const body  = {
                startDate: startDate.toISOString(),
                endDate: endDate.toISOString(),
                pageNumber:pageNumber,
                clinicId: clinicId,
            }
            fetchTransactions(
                { url: '/finance/transactions?'+Object.keys(body).map(key => key + '=' + body[key]).join('&'),
                    method: "GET"
                    // headers: {
                    //     'Content-Type': 'application/json'
                    // }
                },
                transformTransactions
            );
        }
    }, [endDate, pageNumber, refresh]);

    const onChange = (dates) => {
        setDateRange(dates);
    };

    const onPrevious = () => {
        const updated = pageNumber
        setPageNumber(updated-1)
    }

    const onNext = () => {
        const updated = pageNumber
        setPageNumber(updated+1)
    }
    const cancelTransaction = (event) => {
        setShowCancelTransactionModal(true);
        setSelectedTransationId(event.currentTarget.id)
    }

    const handleCloseTransactionModal = () => {
        setShowCancelTransactionModal(false);
        setRefresh(!refresh)
    }


    return(
        <>
            <Layout>
            <br/>
            <Container>
                <Card>
                    <Card.Header>
                        <Row>
                            <Col sm={8} xs={6} md={9}><h3>Transactions</h3></Col>

                            <Col sm={4} xs={6} md={3}>
                                <DatePicker
                                    customInput={<Form.Control />}
                                    selectsRange={true}
                                    startDate={startDate}
                                    endDate={endDate}
                                    onChange={onChange}
                                />
                            </Col>
                        </Row>
                    </Card.Header>
                    <Card.Body>
                        <Table striped bordered hover  responsive>
                            <thead>
                            <tr>
                                <th>Id</th>
                                <th>Patient</th>
                                <th>Amount</th>
                                <th>Date</th>
                                <th>Type</th>
                                <th>Comment</th>
                                <th>Wallet Balance</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                            </thead>
                            <tbody>
                            {transactions?.content !=null && transactions.content.map(transaction => {
                                    return <tr key={transaction.id}>
                                        <td>{transaction.id}</td>
                                        <td>{transaction.patientName}</td>
                                        <td>&#8377; {transaction.amount}</td>
                                        <td>{formatShortDateOnly(transaction.createdAt)}</td>
                                        <td>{transaction.transactionType}</td>
                                        <td>{transaction.comment}</td>
                                        <td>&#8377; {transaction.balance}</td>
                                        <td>{transaction.status}</td>
                                        <td>{transaction.isCancelable &&
                                            <Button id={transaction.id}  onClick={cancelTransaction} >Cancel</Button>
                                        }</td>
                                    </tr>
                                }
                            )}
                            </tbody>
                        </Table>
                    </Card.Body>
                    <Card.Footer>
                        <Pagination
                            itemsPerPage={transactions?.size}
                            items={transactions?.totalElements}
                            totalPages ={transactions?.totalPages}
                            onPravious={onPrevious}
                            onNext={onNext}

                        />
                    </Card.Footer>
                </Card>
            </Container>
            </Layout>
            {showCancelTransactionModal &&
                <CancelPayment
                    show={showCancelTransactionModal}
                    transactionId={selectedTransactionId}
                    handleClose={handleCloseTransactionModal}
                />
            }
        </>
    )
}

export default TransactionList;