import React, {useContext, useEffect, useState} from "react";
import useHttpRequest from "../../hooks/useHttpRequest";
import {Card, Col, Container, Form, Row, Table} from "react-bootstrap";
import DatePicker from "react-datepicker";
import {formatDate} from "../../AppUtility";
import Pagination from "../Pagination/Pagination";
import Button from "react-bootstrap/Button";
import AddPrescription from "./AddPrescription";
import AuthContext from "../../context/AuthContext";
import Layout from "../../UI/Layout";


const PrescriptionList = () => {
    const fromDate = new Date();
    const { id, name, token, clinicId} = useContext(AuthContext)
    fromDate.setDate(fromDate.getDate()-14)
    const [prescriptions, setPrescriptions] = useState(null);
    const [editablePrescription, setEditablePrescription] = useState(null);
    const [pageNumber, setPageNumber] = useState(0)
    const [dateRange, setDateRange] = useState([fromDate,new Date()]);
    const [startDate, endDate] =dateRange;
    const { isLoading, error, sendRequest: fetchPrescriptions } = useHttpRequest();
    const [showPrescriptionModal, setShowPrescriptionModal] = useState(false);
    const [refresh, setRefresh] = useState(true);

    useEffect(() => {
        const transformPrescriptions = (prescriptions) => {

            setPrescriptions(prescriptions);
        };

        if(startDate != null && endDate != null && refresh === true){
            const body  = {
                startDate: startDate.toISOString(),
                endDate: endDate.toISOString(),
                pageNumber:pageNumber,
                clinicId: clinicId
            }
            fetchPrescriptions(
                { url: '/prescriptions?'+Object.keys(body).map(key => key + '=' + body[key]).join('&'),
                    method: "GET"
                },
                transformPrescriptions
            );
        }
    }, [endDate, pageNumber, refresh]);

    const editPrescription = (event) =>{
        const editablePrescription = prescriptions?.content?.find(element => element.id == event.currentTarget.id);
        setEditablePrescription(editablePrescription);
        setShowPrescriptionModal(true);
        setRefresh(false)
    }

    const handleClosePrescriptionModal = () => {
        setShowPrescriptionModal(false);
        setRefresh(true)
    }


    const onChange = (dates) => {
        setDateRange(dates);
    };

    const onPrevious = () => {
        const updated = pageNumber
        setPageNumber(updated-1)
    }

    const onNext = () => {
        const updated = pageNumber
        setPageNumber(updated+1)
    }

    return(
        <>
            <Layout>
            <br/>
            <Container>
                <Card>
                    <Card.Header>
                        <Row>
                            <Col sm={8} xs={6} md={9}><h3>Prescriptions</h3></Col>
                            <Col sm={4} xs={6} md={3}>
                                <DatePicker
                                    customInput={<Form.Control />}
                                    selectsRange={true}
                                    startDate={startDate}
                                    endDate={endDate}
                                    onChange={onChange}
                                />
                            </Col>
                        </Row>
                    </Card.Header>
                    <Card.Body>
                        <Table striped bordered hover  responsive>
                            <thead>
                            <tr>
                                <th>Id</th>
                                <th>Patient</th>
                                <th>Created Date</th>
                                <th>Update Date</th>
                                <th>Action</th>
                            </tr>
                            </thead>
                            <tbody>
                            {prescriptions?.content !=null && prescriptions.content.map(prescription => {
                                    return <tr key={prescription.id}>
                                        <td>{prescription.id}</td>
                                        <td>{prescription.patientName}</td>
                                        <td>{formatDate(prescription.createdAt)}</td>
                                        <td>{formatDate(prescription.updatedAt)}</td>
                                        <td>
                                            <Button id={prescription.id} onClick={editPrescription}>Edit</Button>
                                        </td>
                                    </tr>
                                }
                            )}
                            </tbody>
                        </Table>
                    </Card.Body>
                    <Card.Footer>
                        <Pagination
                            itemsPerPage={prescriptions?.size}
                            items={prescriptions?.totalElements}
                            totalPages ={prescriptions?.totalPages}
                            onPravious={onPrevious}
                            onNext={onNext}

                        />
                    </Card.Footer>
                </Card>
            </Container>
            </Layout>
            {showPrescriptionModal &&
            <AddPrescription
                show={showPrescriptionModal}
                prescriptionId={editablePrescription?.id}
                medicines={editablePrescription?.medicines}
                handleClose={handleClosePrescriptionModal}/>
            }

        </>
    )
};

export default PrescriptionList;