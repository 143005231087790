import React, {useContext, useEffect, useState} from "react";
import AuthContext from "../../context/AuthContext";
import useHttpRequest from "../../hooks/useHttpRequest";
import Layout from "../../UI/Layout";
import {Card, Col, Container, Form, Row, Table} from "react-bootstrap";
import DatePicker from "react-datepicker";
import {formatShortDateOnly} from "../../AppUtility";
import Pagination from "../Pagination/Pagination";


const OrdersList = () => {
    const fromDate = new Date();
    const { id, name, token, clinicId} = useContext(AuthContext)
    fromDate.setDate(fromDate.getDate()-14)
    const [orders, setOrders] = useState(null);
    const [orderStats, setOrderStats] = useState([]);
    const [editableOrder, setEditableOrder] = useState(null);
    const [pageNumber, setPageNumber] = useState(0)
    const [dateRange, setDateRange] = useState([fromDate,new Date()]);
    const [startDate, endDate] =dateRange;
    const { isLoading, error, sendRequest: fetchOrders } = useHttpRequest();
    const { sendRequest: fetchProductOrderStats } = useHttpRequest();
    const [showOrderModal, setShowOrderModal] = useState(false);
    const [refresh, setRefresh] = useState(true);

    useEffect(() => {
        const transformOrders = (item) => {

            setOrders(item);
        };

        if(startDate != null && endDate != null && refresh === true){
            const body  = {
                startDate: startDate.toISOString(),
                endDate: endDate.toISOString(),
                pageNumber:pageNumber,
                clinicId: clinicId
            }
            fetchOrders(
                { url: '/orders?'+Object.keys(body).map(key => key + '=' + body[key]).join('&'),
                    method: "GET"
                },
                transformOrders
            );
        }
    }, [endDate, pageNumber, refresh]);

    useEffect(() => {
        const transformOrderStats = (items) => {

            setOrderStats(items);
        };
        const body  = {
            startDate: startDate?.toISOString(),
            endDate: endDate?.toISOString(),
            clinicId: clinicId
        }
        fetchProductOrderStats(
            { url: '/orders/stats/?'+Object.keys(body).map(key => key + '=' + body[key]).join('&')},
            transformOrderStats
        );
    }, [startDate, endDate]);


    const editOrder = (event) =>{
        const editableOrder = orders?.content?.find(element => element.id == event.currentTarget.id);
        setEditableOrder(editableOrder);
        setShowOrderModal(true);
        setRefresh(false)
    }

    const handleCloseOrderModal = () => {
        setShowOrderModal(false);
        setRefresh(true)
    }


    const onChange = (dates) => {
        setDateRange(dates);
    };

    const onPrevious = () => {
        const updated = pageNumber
        setPageNumber(updated-1)
    }

    const onNext = () => {
        const updated = pageNumber
        setPageNumber(updated+1)
    }

    return(
        <>
            <Layout>
                <br/>
                <Container>
                    <Card>
                        <Card.Header>
                            <Row>
                                <Col sm={8} xs={6} md={9}><h3>Product Orders</h3></Col>
                                <Col sm={4} xs={6} md={3}>
                                    <DatePicker
                                        customInput={<Form.Control />}
                                        selectsRange={true}
                                        startDate={startDate}
                                        endDate={endDate}
                                        onChange={onChange}
                                    />
                                </Col>
                            </Row>
                        </Card.Header>
                        <Card.Body>
                            <Table striped bordered hover  responsive>
                                <thead>
                                <tr>
                                    <th>Id</th>
                                    <th>Patient</th>
                                    <th>Order Date</th>
                                    <th>No. of Products</th>
                                    <th>Total Price</th>
                                    <th>Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                {orders?.content !=null && orders.content.map(order => {
                                        return <tr key={order.id}>
                                            <td>{order.id}</td>
                                            <td>{order.patientName}</td>
                                            <td>{formatShortDateOnly(order.createdAt)}</td>
                                            <td>{order.items.length}</td>
                                            <td>&#8360; {order.totalPrice}</td>
                                            <td>
                                                <p><b>Have patience - Coming soon feature to edit order or see details</b></p>
                                                {/*<Button id={order.id} onClick={editableOrder}>Edit</Button>*/}
                                                {/*<Button id={order.id} onClick={editableOrder}>Details</Button>*/}
                                            </td>
                                        </tr>
                                    }
                                )}
                                </tbody>
                            </Table>
                        </Card.Body>
                        <Card.Footer>
                            <Pagination
                                itemsPerPage={orders?.size}
                                items={orders?.totalElements}
                                totalPages ={orders?.totalPages}
                                onPravious={onPrevious}
                                onNext={onNext}

                            />
                        </Card.Footer>
                    </Card>
                    <br/>
                    <br/>
                    <Card>
                        <Card.Header>
                            <Row>
                                <Col sm={8} xs={6} md={9}><h3>Product Orders Stats</h3></Col>
                                <Col sm={4} xs={6} md={3}>
                                    <DatePicker
                                        customInput={<Form.Control />}
                                        selectsRange={true}
                                        startDate={startDate}
                                        endDate={endDate}
                                        onChange={onChange}
                                    />
                                </Col>
                            </Row>
                        </Card.Header>
                        <Card.Body>
                            <Table striped bordered hover  responsive>
                                <thead>
                                <tr>
                                    <th>Id</th>
                                    <th>Name</th>
                                    <th>Quantity Sold</th>
                                </tr>
                                </thead>
                                <tbody>
                                {orderStats !=null && orderStats?.items?.map(order => {
                                        return <tr key={order.id}>
                                            <td>{order.id}</td>
                                            <td>{order.name}</td>
                                            <td>{order.sold}</td>
                                        </tr>
                                    }
                                )}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </Container>
            </Layout>
            {/*{showOrderModal &&*/}
            {/*    <AddPrescription*/}
            {/*        show={showOrderModal}*/}
            {/*        prescriptionId={editableOrder?.id}*/}
            {/*        medicines={editableOrder?.items}*/}
            {/*        handleClose={handleCloseOrderModal}/>*/}
            {/*}*/}

        </>
    )
};

export default OrdersList;